import { useEffect, useState, useRef, useCallback } from "react"
// https://www.npmjs.com/package/react-hook-search

import ModalPersonInput from "./ModalPersonInput"
import SelectedPeople from "./SelectedPeople"


//BOOTSTRAP COMPONENTS
import Button from "react-bootstrap/Button"
import Table from "react-bootstrap/Table"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"



export default function TableTwo({ people }) {
    const [filteredPeople, setFilteredPeople] = useState(people)
    //this is the list of people that the table pulls from!
    const [activePerson, setActivePerson] = useState(null)
    //we need to specify which person record is active
    const [bayAreaOnly, setBayAreaOnly] = useState(false)
    const [sortBy, setSortBy] = useState("createdAt")
    //sort records by createdAt
    const [sortDescending, setSortDescending] = useState(false)
    //sort our columns in desc/asc order
    const [selectedPeople, setSelectedPeople] = useState([])
    //for our SELECTED PEOPLE box!
    const [excludeEmpty, setExcludeEmpty] = useState(false)
    const [showInput, setShowInput] = useState(false)
    //toggle whether to show the 'edit this person' input
    const [showDelete, setShowDelete] = useState(false)
    //are you SURE you wanna delete this record?!~?!
    const [showSubmit, setShowSubmit] = useState(false)
    //this state is for our modal. it controls whether you see a big "SUBMIT" button or an ARE  YOU SURE confirmation/cancel pair of buttons

    const allPeople = useRef(people).current
    //i want to store our "people" prop in an unchanging ref value! REMEMBER: YOU HAVE TO USE .current, because the ref is an object with a "current" property

    const bayAreaPeople = useRef(
        allPeople.filter(
            person =>
                person.zip &&
                person.zip.substring(0, 5) >= 94000 &&
                person.zip.substring(0, 5) <= 94999
        )
    ).current
    // if the first 5 characters of the zip are inside this range, that means they're somewhere near sf, right?

    const toggleBayArea = () => {
        //toggle the boolean bayAreaOnly state
        setBayAreaOnly(previousState => !previousState)
    }

    const sorted = useCallback(
        arrayOfPeople => {
            //call sorted(anyArray) and it'll return the array again, but sorted by whatever value is in our sortBy state

            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/Collator
            const options = {
                sensitivity: "base",
                // Only strings that differ in base letters compare as unequal. Examples: a ≠ b, a = á, a = A.
                ignorePunctuation: true,
                numeric: true,
                // Whether numeric collation should be used, such that "1" < "2" < "10".
            }

            //if sortDescending is true, do it in descending order
            if (sortDescending) {
                return arrayOfPeople.sort((a, b) => {
                    if (!a[sortBy]) {
                        a[sortBy] = ""
                    }

                    if (!b[sortBy]) {
                        b[sortBy] = ""
                    }

                    return a[sortBy]
                        .toString()
                        .localeCompare(b[sortBy].toString(), options)
                })
            } else {
                //otherwise it'll be false, sort in descending order!
                return arrayOfPeople.sort((a, b) => {
                    if (!a[sortBy]) {
                        a[sortBy] = ""
                    }

                    if (!b[sortBy]) {
                        b[sortBy] = ""
                    }

                    return b[sortBy]
                        .toString()
                        .localeCompare(a[sortBy].toString(), options)
                })
            }
        },
        [sortBy, sortDescending]
    )

    const changeExclusion = () => {
        // console.log("exclude empty? ", excludeEmpty)
        setExcludeEmpty(prevState => !prevState)
    }

    const changeSortOrder = () => {
        console.log("changing sort order!")
        console.log("sorting in descending order?", sortDescending)
        setSortDescending(prevState => !prevState)
        //toggle the sorting order, it's either true (ascending) or false
    }

    const onlyNonEmpty = useCallback(
        //wrapping this function in useCallback to get rid of a console error React was giving me... but yeah: we dont want this function to be recreated every single time, because it will be, and it'll cause our useEffect that depends on it to rerun over and over!
        arrayOfPeople => {
            return arrayOfPeople.filter(person => person[sortBy])
            //if this person object has a truthy value for the field we're sorting by? it'll be returned here!
        },
        [sortBy]
    )

    const sortByHeading = headingValue => {
        setSortBy(headingValue)

        changeSortOrder() //and flip the sort order too!

        changeActiveColumn(headingValue)
    }

    const changeActiveColumn = columnName => {
        const allHeadings = document.querySelectorAll("th")
        allHeadings.forEach(el => el.classList.remove("active-column"))
        //first, remove all active classes!

        const heading = document.querySelector(`#${columnName}`)

        heading.classList.toggle("active-column")
        // grab the table heading with the id corresponding with the column name, then toggle the active-colum class
    }

    const handleEdit = e => {
        e.stopPropagation()
        setShowInput(prevState => !prevState)
    }
    const handleClose = () => {
        setShowInput(false)
    }

    //any time our sorting value changes, our exclusion checkbox changes, a search term is entered, or our bayareaonly button is clicked.... do this:
    useEffect(() => {
        console.log("big if/else useEffect fired")

        //there are 4 possible cases of combinations of bay and exclude

        //bayAreaOnly and excludeEmpty:
        if (bayAreaOnly && excludeEmpty) {
            setFilteredPeople(onlyNonEmpty(sorted(bayAreaPeople)))
        }

        //bayAreaOnly and !excludeEmpty:
        if (bayAreaOnly && !excludeEmpty) {
            setFilteredPeople(sorted(bayAreaPeople))
        }

        //!bayAreaOnly and excludeEmpty:
        if (!bayAreaOnly && excludeEmpty) {
            setFilteredPeople(onlyNonEmpty(sorted(allPeople)))
        }

        // !bayAreaOnly and !excludeEmpty: we're talking about ALL PEOPLE
        if (!bayAreaOnly && !excludeEmpty) {
            setFilteredPeople(sorted(allPeople))
        }
        // }
    }, [
        sortBy,
        sorted,
        bayAreaOnly,
        bayAreaPeople,
        onlyNonEmpty,
        excludeEmpty,
        allPeople,
    ])

    return (
        <div>
            <Container>
                <Row className="">
                    <Col>
                        <section>
                            <h2>
                                Showing{" "}
                                {filteredPeople && filteredPeople.length}{" "}
                                records,{" "}
                            </h2>

                            <>
                                <h2>
                                    residing{" "}
                                    {bayAreaOnly
                                        ? "in the Bay Area"
                                        : "anywhere"}
                                    .
                                </h2>
                                <h6>Sorting by: {sortBy}</h6>

                                {bayAreaOnly ? (
                                    <Button
                                        aria-label="Show Members From Anywhere"
                                        className="btn-outline-warning"
                                        variant="primary"
                                        onClick={toggleBayArea}
                                    >
                                        Show Members From Anywhere
                                    </Button>
                                ) : (
                                    <Button
                                        aria-label="Show Members From Anywhere"
                                        className="btn-outline-success"
                                        variant="primary"
                                        onClick={toggleBayArea}
                                    >
                                        Show Bay Area Only
                                    </Button>
                                )}
                            </>
                        </section>
                    </Col>

                    <Col
                        md={6}
                        className="my-3 border border-3 border-black color-secondary overflow-scroll"
                    >
                        <>
                            <SelectedPeople
                                selectedPeople={selectedPeople}
                                setSelectedPeople={setSelectedPeople}
                                filteredPeople={filteredPeople}
                                actovePerson={activePerson}
                                setActivePerson={setActivePerson}
                            />
                        </>
                    </Col>
                </Row>

                <Row>
                    <label className="my-2">
                        <span>
                            Exclude Records With Empty{" "}
                            <strong>
                                <em>{sortBy} </em>
                            </strong>{" "}
                            Field
                        </span>
                        <input
                            type="checkbox"
                            onChange={changeExclusion}
                            className="m-2"
                        />
                    </label>
                </Row>

                <Row>
                    {showInput && (
                        <ModalPersonInput
                            thisPerson={activePerson}
                            showDelete={showDelete}
                            setShowDelete={setShowDelete}
                            handleClose={handleClose}
                            showInput={showInput}
                            setActivePerson={setActivePerson}
                            //we're also now passing down this setShowSubmit function, so we can set this state to false every time we hit click a new person's record, so we have the right button in place each time the modal opens!
                            setShowSubmit={setShowSubmit}
                            showSubmit={showSubmit}
                        />
                    )}
                </Row>
            </Container>
            <Container fluid>
                <Table
                    striped
                    bordered
                    variant=""
                    size="sm"
                    responsive
                    className="mt-3"
                >
                    <thead>
                        <tr style={{ cursor: "pointer" }}>
                            <th
                                id="isSelected"
                                onClick={e => {
                                    sortByHeading("isSelected")
                                }}
                            >
                                [select]
                            </th>
                            <th
                                id="id"
                                onClick={e => {
                                    sortByHeading("id")
                                }}
                            >
                                Firestore ID
                            </th>
                            <th
                                id="createdAt"
                                onClick={e => {
                                    sortByHeading("createdAt")
                                }}
                            >
                                Created At
                            </th>

                            <th
                                id="dueDate"
                                onClick={e => {
                                    sortByHeading("dueDate")
                                }}
                            >
                                Due Date
                            </th>
                            <th
                                id="email"
                                onClick={e => {
                                    sortByHeading("email")
                                }}
                            >
                                Email
                            </th>
                            <th
                                id="lastName"
                                onClick={e => {
                                    sortByHeading("lastName")
                                }}
                            >
                                Last Name
                            </th>
                            <th
                                id="firstName"
                                onClick={e => {
                                    sortByHeading("firstName")
                                }}
                            >
                                First Name
                            </th>

                            <th
                                id="address"
                                onClick={e => {
                                    sortByHeading("address")
                                }}
                            >
                                Address
                            </th>
                            <th
                                id="apt"
                                onClick={e => {
                                    sortByHeading("apt")
                                }}
                            >
                                Apartment
                            </th>
                            <th
                                id="city"
                                onClick={e => {
                                    sortByHeading("city")
                                }}
                            >
                                City
                            </th>
                            <th
                                id="state"
                                onClick={e => {
                                    sortByHeading("state")
                                }}
                            >
                                State
                            </th>
                            <th
                                id="zip"
                                onClick={e => {
                                    sortByHeading("zip")
                                }}
                            >
                                Zip
                            </th>
                            <th
                                id="phone"
                                onClick={e => {
                                    sortByHeading("phone")
                                }}
                            >
                                Phone
                            </th>

                            <th
                                id="duesPaid"
                                onClick={e => {
                                    sortByHeading("duesPaid")
                                }}
                            >
                                Most Recent Dues
                            </th>

                            <th
                                id="organization"
                                onClick={e => {
                                    sortByHeading("organization")
                                }}
                            >
                                Organization
                            </th>

                            <th
                                id="comments"
                                onClick={e => {
                                    sortByHeading("comments")
                                }}
                            >
                                Comments
                            </th>

                            <th
                                id="national"
                                onClick={e => {
                                    sortByHeading("national")
                                }}
                            >
                                National (legacy)
                            </th>
                            <th
                                id="status"
                                onClick={e => {
                                    sortByHeading("status")
                                }}
                            >
                                Renewal Comment
                            </th>
                            <th
                                id="old_ID"
                                onClick={e => {
                                    sortByHeading("old_ID")
                                }}
                            >
                                Old_ID (legacy)
                            </th>

                            <th
                                id="joined"
                                onClick={e => {
                                    sortByHeading("joined")
                                }}
                            >
                                "joined" (legacy)
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {filteredPeople &&
                            sorted(filteredPeople).map(person => {
                                return (
                                    <tr
                                        key={person.id}
                                        onClick={() => {
                                            if (!person.isSelected) {
                                                person.isSelected = true
                                                setSelectedPeople(prevState => [
                                                    ...prevState,
                                                    person,
                                                ])
                                            } else {
                                                person.isSelected = false
                                                setSelectedPeople(prevState =>
                                                    prevState.filter(
                                                        selectedPerson =>
                                                            selectedPerson.id !=
                                                            person.id
                                                    )
                                                )
                                            }
                                            if (
                                                activePerson &&
                                                activePerson.id === person.id
                                            ) {
                                                setActivePerson(null)
                                            } else {
                                                setActivePerson(person)
                                            }
                                        }}
                                    >
                                        <td>
                                            {activePerson &&
                                            activePerson.id === person.id ? (
                                                <>
                                                    <Button
                                                        onClick={handleEdit}
                                                    >
                                                        Edit
                                                    </Button>
                                                </>
                                            ) : (
                                                ""
                                            )}

                                            {/* <p>{person.isSelected && "X"}</p> */}
                                            {/* if the active person's id is the same as "person"'s id... then that means that person is the activePerson, aka person is selected! so put an x on their row */}
                                        </td>
                                        <td>{person.id}</td>
                                        <td>
                                            {person.createdAt &&
                                                person.createdAt
                                                    .toDate()
                                                    .toISOString()
                                                    .slice(0, 11)
                                                    .replace("T", " ")}
                                        </td>

                                        <td>
                                            {person.dueDate &&
                                                (typeof person.dueDate ===
                                                "object"
                                                    ? //if their due date is a string, then convert it to a date!

                                                      person.dueDate
                                                          .toDate()
                                                          .toISOString()
                                                          .slice(0, 11)
                                                          .replace("T", " ")
                                                    : "not current")}
                                            {/* ok... because dueDate is a messy data field... we're checking to see if dueDate EXISTS, and if it does, if it's a string, display it, if not?! it's a timestamp, so turn it into a date, and cut it off after the 11th character so we're not looking at the time */}
                                        </td>
                                        <td>{person.email}</td>
                                        <td>{person.lastName}</td>
                                        <td>{person.firstName}</td>
                                        <td>{person.address}</td>
                                        <td>{person.apt}</td>
                                        <td>{person.city}</td>
                                        <td>{person.state}</td>
                                        <td>{person.zip}</td>
                                        <td>{person.phone}</td>
                                        <td>{person.duesPaid}</td>
                                        <td>{person.organization}</td>
                                        <td>{person.comments}</td>
                                        <td>{person.national}</td>
                                        <td>{person.status}</td>
                                        <td>{person.old_ID}</td>
                                        {/* IF the record has a JOINED field, it means their record was imported from the old dataset, and JOINED is  a string! */}
                                        <td>
                                            {person.joined &&
                                                person.joined !== " " &&
                                                typeof person.joined ===
                                                    "string" &&
                                                person.joined}
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </Table>
            </Container>
        </div>
    )
}
