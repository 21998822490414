import { useState } from "react"
import { useLogin } from "../hooks/useLogin"
import { Container, Row, Col } from "react-bootstrap"

//styles:
// import styles from "./Login.module.css"

export default function Login() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const { login, error, isPending, provider } = useLogin()

    const handleSubmit = e => {
        e.preventDefault()
        login(email, password)
        //this function comes from our useLogin hook
    }

    return (
        <>
            <Container>
                <div className="" style={{ margin: "10rem" }}>
                    <h4>Please log in.</h4>

                    <div className="">
                        <form action="" onSubmit={handleSubmit}>
                            <Row className="m-5">
                                <label>
                                    email :
                                    <input
                                        type="text"
                                        onChange={e => setEmail(e.target.value)} 
                                        style={{minWidth: '32rem'}}
                                    />
                                </label>
                            </Row>
                            <Row className="m-5">
                                <label htmlFor="">
                                    password :
                                    <input
                                        type="password"
                                        onChange={e =>
                                            setPassword(e.target.value)
                                        }
                                        style={{minWidth: '32rem'}}
                                    />
                                </label>
                            </Row>

                            <button type="submit" className="p-2 fs-2">
                                submit
                            </button>
                        </form>
                    </div>
                </div>
            </Container>
        </>
    )
}
