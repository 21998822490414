import { useState, useEffect } from "react"

import MyNavbar from "../components/MyNavbar"
import Footer from "../components/Footer"
import EmailForm from "../components/EmailForm"
import ContactForm from "../components/ContactForm"
// https://formspree.io/forms/mlezkzlq/integration

import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"

export default function Contact() {
    return (
        <div
            className="bg-primary "
            style={{
                backgroundImage: `linear-gradient(rgb(109, 149, 180, 0.01), rgb(255, 253, 218, 0.4))`,
            }}
        >
            <MyNavbar />

            <Container fluid>
                <Row className="justify-content-center">
                    <Col md={8} lg={6} className="p-4 mt-4 color-primary">
                        <h1 className="text-center display-2 ">Contact Us</h1>
                    </Col>
                </Row>

                <Row className="justify-content-center my-5 px-5 gx-1">
                    <Col className="">
                        <ContactForm />
                    </Col>
                    <Col>
                        <Row className="m-auto mt-5 ">
                            <EmailForm />
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </div>
    )
}
