//this is called MYNAVBAR to distinguish from react bootstrap NAVBAR
// https://bootswatch.com/lux/
import { Link, NavLink } from "react-router-dom"
import logo from "../gp.gif"
//bootstrap styles
import { Container, Row, Col, Navbar, Nav, Image } from "react-bootstrap"

export default function MyNavBar() {
    let activeStyle = {
        textDecoration: "underline",

        // backgroundColor: "var(--color-secondary)",
        color: "var(--color-link)",
    }

    let activeClassName = "underline"

    return (
        <>

         
                <Container fluid
                tabIndex='0'
                >
                    {/* changed this container to FLUID to reduce the margins on either side */}

                    <Row 
                    className=''
                    // className='align-items-center justify-content-around m-0  '
                    >
                        {/* added this ROW as a direct child of the above container */}



                   <Navbar
                // style={{ margin: '0', maxHeight: '16rem' }}
                
                sticky="top"
                bg="light"
                expand="lg"
                className="m-0"
                // style={{ maxHeight: "10rem" }}
                >
                {/* <Col  > */}
                   <Navbar.Brand href="/" >



              
                        <Image
                            fluid
                            className="panther"
                            // this classname is here so i can exclude this image from having a black border!
                            // tabIndex='0'
                            src={logo}
                            alt={"Link to SFGP Home Page"}
                            style={{ maxHeight: "8rem" }}
                        >
                            {/* fluid scales the image to the parent element! */}
                        </Image>

                     
                    </Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls="basic-navbar-nav"
                        className="text-black "
                    />
                 
                   {/* </Col> */}
                
       

                    <Col xl={9}   className='p-xl-4'>
                 
                    <Navbar.Collapse id="basic-navbar-nav" className='justify-content-center'>
             
                        <Nav
                            // className="top-bar px-md-5 py-4"
                            className='rounded  p-3'
                            >
                          <Row 
                        //   className='justify-content-between align-items-between flex-col'
                            //className='nav-background' //more styles in index.css
                            // className="align-items-center justify-content-between p-2 mx-0"
                            // style={{ maxWidth: '' }}
                          
                         >
                         
                            
                                <Col  >
                                    <NavLink
                                        style={({ isActive }) =>
                                            isActive ? activeStyle : undefined
                                        }
                                        to="/"
                                    >
                                        Home
                                    </NavLink>
                                </Col>

                                <Col >
                                    <NavLink 
                                
                                        style={({ isActive }) =>
                                            isActive ? activeStyle : undefined
                                        }
                                        // className={isActive =>
                                        //     'nav-link' +
                                        //     (!isActive ? ' unselected' : '')
                                        // }
                                        to="/about"
                                    >
                                        {/* About Us */}About
                                    </NavLink>
                                </Col>

                                <Col >
                                    <NavLink
                                        style={({ isActive }) =>
                                            isActive ? activeStyle : undefined
                                        }
                                        // className={isActive =>
                                        //     'nav-link' +
                                        //     (!isActive ? ' unselected' : '')
                                        // }
                                        to="/activism"
                                    >
                                        Activism
                                    </NavLink>
                                </Col>

                                <Col >
                                    <NavLink
                                        style={({ isActive }) =>
                                            isActive ? activeStyle : undefined
                                        }
                                        // className={isActive =>
                                        //     'nav-link' +
                                        //     (!isActive ? ' unselected' : '')
                                        // }
                                        to="/events"
                                    >
                                        Events
                                    </NavLink>
                                </Col>

                                <Col >
                                    <NavLink
                                    className=''
                                        style={({ isActive }) =>
                                            isActive ? activeStyle : undefined
                                        }
                                       
                                        to="/action"
                                    >
                                       

                                        Prowl+Growl
                                    
                                    </NavLink>
                                </Col>

                                <Col >
                                    <NavLink
                                        style={({ isActive }) =>
                                            isActive ? activeStyle : undefined
                                        }
                                        // className={isActive =>
                                        //     'nav-link' +
                                        //     (!isActive ? ' unselected' : '')
                                        // }
                                        to="/resources"
                                    >
                                        Resources
                                    </NavLink>
                                </Col>
                                <Col >
                                    <NavLink
                                        style={({ isActive }) =>
                                            isActive ? activeStyle : undefined
                                        }
                                 
                                        to="/contact"
                                    >
                                        Contact
                                    </NavLink>
                                </Col>
                            </Row>
                        </Nav>
                    </Navbar.Collapse>
                    
                    </Col>

               
 <Col
//  style={{minWidth:'fit-content'}}
 >
{/* //  style={{margin: '0'}}>  */}

        <Link
                        to="/join"
                        className="join-us  btn btn-primary m-0    rounded"
                        // style={{color: 'var(--color-secondary'}}
                        // more styliing under .join-us in index.css
                        // style={{minWidth: 'max-content'}}
                  
                    >
                        Join Us!
                    </Link>


              
</Col>

            </Navbar>
                </Row>
                </Container>
            <Container fluid>
                <Row className="color-tertiary pt-3 border-bottom border-primary border-3">
                  
                </Row>
            </Container>
        </>
    )
}
