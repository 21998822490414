
import {formatAddress} from 'localized-address-format'


export const useAddressFormat = (person) => {

const {firstName, lastName, organization, address, apt, city, state, zip} = person
    
 //this function will format any name with spaces or hyphens!
 const formatName = thisName => {
    // what if there are hyphens? We need capitalization without spacing after each hyphen
    //let's define an inner function to do this:
    const capitalizeAfterHyphen = name => {
        //split the name into an array at each "dash"
        const arr = name.split('-')
        arr.forEach((word, index, array) => {
            //we couldve just used map here...

            //for any word other than the first one...
            array[index] =
                word.substring(0, 1).toUpperCase() +
                word.substring(1).toLowerCase()
            //capitalize the first letter, lowercase the rest of the word
        })

        return arr.join('-') //return the array, joined into a string with hyphens between each element!
    }

    //now that we have our hyphen capitalization, we need space-between words capitalzation!
    //we've already lowercased the endings of words, so all we have to do is capitalize the beginning of words

    const capitalizeAfterSpaces = name => {
        const arr = name.split(' ').map(word => {
            return word.substring(0, 1).toUpperCase() + word.substring(1)
        })
        return arr.join(' ')
    }

    

    return capitalizeAfterSpaces(capitalizeAfterHyphen(thisName))
}



const formattedAddress = formatAddress({
    postalCountry: 'US',
    administrativeArea : state,
    locality: city,
    //dependentLocality: '',
    postalCode: zip,
    //sortingCode: '',
    // organization: 'Example Org.',
    name: `${firstName? formatName(firstName) : (organization? organization : '')} ${lastName? formatName(lastName) : ''}`,
    //if there's a firstname/lastname, capitalize the first letter and lowercase the rest!
    //if there's no first name, then let's put the organization name on the address line

    addressLines : apt ? [`${address}`, `Unit ${apt}`] : [`${address? address : '<no address>'}`], //if there's no apartment number, don't say "Unit," and if there's no address? say "<no address>" instead of <undefined>
})
    return formattedAddress
}