import { useState, useEffect } from "react"
import { projectAuth, provider } from "../firebase/config"
//remember, projectAuth is firebase.auth()
import { useAuthContext } from "./useAuthContext"

export const useLogin = () => {
    const [isCanceled, setIsCanceled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const { dispatch } = useAuthContext()
    //so far, this is all the same as useLogout.js

    // const login = async () => {
    const login = async (email, password) => {
        console.log("tell me email!", email)
        //this function takes email and password options, which will be provided by whatever function imports this hook and then grabs and calls this login function! cool!
        //NOTE: do NOT try to put these arguments in the export const useLogin()=> parenthesis, like i did!
        setError(null)
        setIsPending(true)
        //reset the error state, and make sure the pending state is true since we have a request in progress!

        //sign the user in:
        try {
            const res = await projectAuth.signInWithEmailAndPassword(
                email,
                password
            )
            // remember: this returns an object and one of its properties is "user," so when we assign this object to a variable we get at it with res.user. This is gonna become our payload for when we fire the dispatch function with an action type of LOGIN

            const user = res.user
            //dispatch logout action:
            dispatch({ type: "LOGIN", payload: user })
            //the dispatch function updates our AuthContext state to be... whoever this user is!

            //update the state:
            if (!isCanceled) {
                //isCanceled is only true if this component is unmounted, so this block ensures that we will NOT update the state on an unmounted component! cool!
                setIsPending(false)
                setError(null)
            }
        } catch (err) {
            if (!isCanceled) {
                console.log(err.message)
                setError(err.message)
                setIsPending(false)
            }
        }
    }

    //we need a cleanup function, just in case whatever component that's using this hook unmounts!
    //this useEffect will run immediately when this component is mounted, and will only run the cleanup function in the return statement if the component is unmounted
    useEffect(() => {
        return () => setIsCanceled(true)
        //if this component unmounts, we set isCanceled to true, and therefore DO NOT update any local state!
    }, [])

    return { login, error, isPending }
}

//BELOW IS THE ENTIRE OLD HOOK! back when we were using email, pass
// import { useState, useEffect } from 'react'
// import { projectAuth } from '../firebase/config'
// import { useAuthContext } from './useAuthContext'

// export const useLogin = () => {
//     const [isCanceled, setIsCanceled] = useState(false)
//     const [error, setError] = useState(null)
//     const [isPending, setIsPending] = useState(false)
//     const { dispatch } = useAuthContext()
//     //so far, this is all the same as useLogout.js

//     const login = async (email, password) => {
//         //this function takes email and password options, which will be provided by whatever function imports this hook and then grabs and calls this login function! cool!
//         //NOTE: do NOT try to put these arguments in the export const useLogin()=> parenthesis, like i did!
//         setError(null)
//         setIsPending(true)
//         //reset the error state, and make sure the pending state is true since we have a request in progress!

//         //sign the user in:
//         try {
//             const res = await projectAuth.signInWithEmailAndPassword(email, password)
//             //remember: this shit returns an object and one of its properties is "user," so when we assign this object to a variable we get at it with res.user. This is gonna become our payload for when we fire the dispatch function with an action type of LOGIN

//             //dispatch logout action:
//             dispatch({ type: 'LOGIN', payload: res.user })
//             //the dispatch function updates our AuthContext state to be... whoever this user is!

//             //update the state:
//             if (!isCanceled) {
//                 //isCanceled is only true if this component is unmounted, so this block ensures that we will NOT update the state on an unmounted component! cool!
//                 setIsPending(false)
//                 setError(null)
//             }
//         } catch (err) {
//             if (!isCanceled) {
//                 console.log(err.message)
//                 setError(err.message)
//                 setIsPending(false)
//             }
//         }
//     }

//     //we need a cleanup function, just in case whatever component that's using this hook unmounts!
//     //this useEffect will run immediately when this component is mounted, and will only run the cleanup function in the return statement if the component is unmounted
//     useEffect(() => {
//         return () => setIsCanceled(true)
//         //if this component unmounts, we set isCanceled to true, and therefore DO NOT update any local state!
//     }, [])

//     return { login, error, isPending }
// }
