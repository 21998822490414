import { useForm, ValidationError } from '@formspree/react';

import { Row, Col, Button } from 'react-bootstrap';

// import EmailForm from './EmailForm'

export default function ContactForm() {
    const [state, handleSubmit] = useForm("mlezkzlq");
    if (state.succeeded) {
        return <p className='text-white'>Your message has been sent to the SF Gray Panthers. Thank you for getting in touch!</p>;
    }
    return (
        <div className='color-secondary py-4 px-md-4 p-lg-4 border border-2 border-primary'>
        <Row className='m-auto'>
      
            <h2 className='text-center'>Send Us An Email</h2>

            </Row>

            <form  onSubmit={handleSubmit}>
            <Row className=''>



                  

   
       
                  

        
                <Row className='p-4 '>
<Col >

        <ValidationError 
          prefix="Email" 
          field="email"
          errors={state.errors}
        />
        <Row>



        <label htmlFor='message' className='text-center'>What would you like to say?   </label>
        </Row>
<Row className='mx-auto '>




        <textarea
        style={{minHeight: '16rem'}}
        className='mt-3 mx-2  p-2'
          id="message"
          name="message"
          placeholder="enter your message here:"
        />
        <ValidationError 
          prefix="Message" 
          field="message"
          errors={state.errors}
        />
        </Row>
     
     
        </Col>
        
     
                </Row>


                <Row className='m-auto px-5 justify-content-center '>


             
    
   
    <Row className=''>
        <Col className='' >
        <Row >


            
<label htmlFor="email" 
// style={{display: 'hidden'}}
>
      What is your email address?
    </label>
    </Row>

    <Row>


    <input className='my-3 p-2'
    placeholder='sfgp@example.com'
          id="email"
          type="email" 
          name="email"
        />
    </Row>
        
    
        </Col>


             
        <Button className='rounded' type="submit" disabled={state.submitting}>
          Send Us A Message!
        </Button>


    </Row>




           

       

           



        </Row>
        </Row>
      </form>
        </div>
      
    );
  
}
