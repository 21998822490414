//REACT BOOTSTRAP COMPONENTS
import {Form, Button, Row, Col} from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function EmailForm() {
  return (
    <div className="mx-3 p-4  px-md-5 color-tertiary border border-2 border-primary mt-lg-0 ">
     

{/* none of this NEEDS to be a form anymore, because I changed the button to a link to the SENDER form... but I didn't wanna restyle everything */}






<Form 
// className="mx-3 p-3 foot-email p-md-4"

>
  <Form.Group  controlId="formBasicEmail"> 
    <Row className='justify-content-center   align-items-center  m-auto'
    // style={{maxWidth: 'max-content'}}
    >
    <Form.Label className='flex justify-content-center text-center'><h3 >Join Our Organization</h3></Form.Label>
    {/* <Form.Control type="email" placeholder="Enter email"
    className='mb-3 rounded border border-2 border-primary' style={{maxWidth: '80%'}} /> */}
   
   <Row />
   <Row 
 className='my-5'
 >

{/* <h3>Become a member of the SF Gray Panthers</h3>
<p>We are a 501c(3) organization</p> */}

 <Link
    to='/join'
    className="join-us  btn btn-primary m-0    rounded"
    >Become A Member</Link>




    </Row>
   <Row className='g-1 m-auto '>

 

  



<Col>
<a href="https://stats.sender.net/forms/aMoJPd/view" 

// className='btn btn-secondary   rounded sign-me-up '
>






    Receive Emails from SFGP

  </a>
</Col>




  
 
{/* 
<Col>
<Form.Text className="text-black mt-4 " style={{display:'inline', maxWidth: 'max-content'}}>
  <p>
 Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate, non debitis deserunt inventore nesciunt soluta sint laboriosam incidunt sequi obcaecati totam a nostrum accusantium quod. Quam dolor laborum facere dignissimos?</p>
   
    </Form.Text>

</Col> */}



    
      
  </Row>
  </Row>
  </Form.Group>



 
 </Form> 


    </div>

  )
}
