import { useState, useEffect } from "react"

import { CSVLink, CSVDownload } from "react-csv"
// https://www.npmjs.com/package/react-csv

import Button from "react-bootstrap/Button"
// import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

export default function SelectedPeople({
    selectedPeople,
    setSelectedPeople,
    filteredPeople,
    activePerson,
    setActivePerson,
}) {
    console.log(selectedPeople)

    // these are the column headings for our CSV:
    const headers = [
        { label: "ID", key: "id" },
        { label: "EMAIL", key: "email" },
        { label: "LAST NAME", key: "lastName" },
        { label: "FIRST NAME", key: "firstName" },
        { label: "JOINED (legacy)", key: "joined" },
        { label: "CREATED AT", key: "createdAt" },
        { label: "DUE DATE", key: "dueDate" },
        { label: "MOST RECENT DUES", key: "duesPaid" },
        { label: "SIGNED UP VIA WEBSITE", key: "signedUpViaWebsite" },
        { label: "ORGANIZATION", key: "organization" },
        { label: "ADDRESS", key: "address" },
        { label: "CITY", key: "city" },
        { label: "STATE", key: "state" },
        { label: "ZIP", key: "zip" },
        { label: "PHONE", key: "phone" },
        { label: "NATIONAL", key: "national" },
        { label: "STATUS", key: "status" },
        { label: "COMMENTS", key: "comments" },
        { label: "OLD ID (legacy)", key: "old_ID" },
    ]

    return (
        <div className="">
            <Row className="g-2 my-3">
                <Col className="">
                    <h6>
                        {selectedPeople && selectedPeople.length} SELECTED
                        PEOPLE
                    </h6>
                </Col>
                <Col>
                    <Button onClick={() => setSelectedPeople(filteredPeople)}>
                        Select All
                    </Button>
                </Col>
                <Col>
                    <Button
                        onClick={() => {
                            setSelectedPeople([])
                        }}
                    >
                        Select None
                    </Button>
                </Col>

                {/* only show the "DOWNLOAD THIS LIST AS A SPREADSHEET" button if we have one or more people selected! */}
                {selectedPeople.length > 0 && (
                    <Row className="m-auto">
                        <Col className="mb-2">
                            <CSVLink data={selectedPeople} headers={headers}>
                                {" "}
                                <Button>
                                    Download This List as a Spreadsheet
                                </Button>
                            </CSVLink>
                        </Col>
                    </Row>
                )}
            </Row>

            {selectedPeople && (
                <ol
                    className="color-primary px-5 mx-3"
              
                >
                    {selectedPeople.map(person => (
                        <li key={person.id} className="p-3">
                            {person.firstName || "(no first name)"}{" "}
                            {person.lastName || "(no last name)"}
                            {"- "}
                            {person.email || "(no email)"}
                        </li>
                    ))}
                </ol>
            )}
        </div>
    )
}
