import { useState, useEffect } from 'react'
import { useFirestore } from '../hooks/useFirestore'
//styles
import styles from './People.module.css'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function EditPerson({
    resetActivePerson,
    resetInput,
    id,
    old_ID,
    firstName,
    lastName,
    address,
    apt,
    city,
    state,
    zip,
    email,
    phone,
    // joined,
    national,
    organization,
    status,
    comments,
    // dueDate,
}) {
    //we're gonna put our fields data in one BIG object, right here
    const [personData, setPersonData] = useState({
        firstName,
        lastName,
        address,
        apt,
        city,
        state,
        zip,
        email,
        phone,
        // joined,
        national,
        organization,
        status,
        comments,
        // dueDate
    })
    const [showSubmit, setShowSubmit] = useState(false)
    //are you sure you want to SUBMIT your edits to this record?
    const [showDelete, setShowDelete] = useState(false)
    //are you SURE you wanna delete this record?!~?!

    const { updateDocument, deleteDocument, response } = useFirestore('people')

    const handleSubmit = e => {
        e.preventDefault() //dont reload the page!

        // const dataToUpdate = {firstName: _firstName}
        const dataToUpdate = personData
        updateDocument(id, dataToUpdate)
    }

    const handleClose = e => {
        //close personinput....
        e.stopPropagation()

        console.log('fired handleclose in editperson.js')
        e.preventDefault() //dont reload the page!
        resetActivePerson()
    }

    const handleDelete = e => {
        e.stopPropagation()
        e.preventDefault()
        setShowDelete(true)

    }

    const areYouSure = e => {
        e.preventDefault()
        setShowSubmit(true)
    }

    const notSure = e => {
        e.preventDefault()
        setShowSubmit(false)
    }

    useEffect(() => {
        //we're going to run this useEffect every time the success property on the response object changes!
        if (response.success) {
            // setFirstName(personData.firstName)

            //when our success property changes, if we have a successful response aka response.success === true, then reset the name field!
            //WE DONT ACTUALLY need to reset the name field in the input, because we're just gonna go back to the Display version where the info is in <p> tagz

            //and then: make the form inactive:
            resetInput()
        }
    }, [response.success, personData, resetInput])

    return (
        <div className='mt-3'>
     
            <div className={styles['update-record']}>
          
                {showDelete && (
                    <div className={styles['are-you-sure']}>
                        <p>Are you sure you want to DELETE THIS RECORD?</p>
                        <button
                            onClick={() => deleteDocument(id)}
                            className={styles.submit}
                        >
                            Yes, delete {firstName} {lastName}
                        </button>
                        <button
                            onClick={() => setShowDelete(false)}
                            className={styles.submit}
                        >
                            No, Never Mind!
                        </button>
                    </div>
                )}

                <form onSubmit={handleSubmit}>

                    <Row>
                        <Col xs={11}>
                        <p>
                        ID: {id}, old id: {old_ID}
                    </p>
                        </Col>
                    <Col xs={1}>
                    <button
                        onClick={handleDelete}
                        className={styles.delete}
                    >
                        X
                    </button></Col>
                    </Row>
                <Row>
              

                    <Col>
                   
                    

                    <label>
                        <span>First Name:</span>

                        <input
                            type="text"
                            className={styles['person-field']}
                            value={personData.firstName}
                            onChange={e => {
                                setPersonData({
                                    ...personData,
                                    firstName: e.target.value,
                                })
                            }}
                        />
                    </label>
                    <label>
                        <span>Last Name:</span>

                        <input
                            type="text"
                            className={styles['person-field']}
                            value={personData.lastName}
                            onChange={e => {
                                setPersonData({
                                    ...personData,
                                    lastName: e.target.value,
                                })
                            }}
                        />
                    </label>

                    <label>
                        <span>Address:</span>

                        <input
                            type="text"
                            className={styles['person-field']}
                            value={personData.address}
                            onChange={e => {
                                setPersonData({
                                    ...personData,
                                    address: e.target.value,
                                })
                            }}
                        />
                    </label>

                    <label>
                        <span>Apt:</span>

                        <input
                            type="text"
                            className={styles['person-field']}
                            value={personData.apt}
                            onChange={e => {
                                setPersonData({
                                    ...personData,
                                    apt: e.target.value,
                                })
                            }}
                        />
                    </label>

                    <label>
                        <span>City:</span>

                        <input
                            type="text"
                            className={styles['person-field']}
                            value={personData.city}
                            onChange={e => {
                                setPersonData({
                                    ...personData,
                                    city: e.target.value,
                                })
                            }}
                        />
                    </label>

                    <label>
                        <span>State:</span>

                        <input
                            type="text"
                            className={styles['person-field']}
                            value={personData.state}
                            onChange={e => {
                                setPersonData({
                                    ...personData,
                                    state: e.target.value,
                                })
                            }}
                        />
                    </label>

                    <label>
                        <span>Zip:</span>

                        <input
                            type="text"
                            className={styles['person-field']}
                            value={personData.zip}
                            onChange={e => {
                                setPersonData({
                                    ...personData,
                                    zip: e.target.value,
                                })
                            }}
                        />
                    </label>
                    </Col>

                    <Col>
                    <label>
                        <span>Email:</span>

                        <input
                            type="text"
                            className={styles['person-field']}
                            value={personData.email}
                            onChange={e => {
                                setPersonData({
                                    ...personData,
                                    email: e.target.value,
                                })
                            }}
                        />
                    </label>
                    <label>
                        <span>Phone:</span>

                        <input
                            type="text"
                            className={styles['person-field']}
                            value={personData.phone}
                            onChange={e => {
                                setPersonData({
                                    ...personData,
                                    phone: e.target.value,
                                })
                            }}
                            // YO YO YO! I CHANGED THIS INPUT TYPE TO 'tel" and it broke everything, so i set it back to a string
                        />
                    </label>

                    <label>
                        <span>Joined Date:</span>

                        <input
                            type="date"
                            className={styles['person-field']}
                            value={personData.joined}
                            onChange={e => {
                                setPersonData({
                                    ...personData,
                                    joined: e.target.value,
                                })
                            }}
                        />
                    </label>

                    <label>
                        <span>National:</span>

                        <input
                            type="text"
                            className={styles['person-field']}
                            value={personData.national}
                            onChange={e => {
                                setPersonData({
                                    ...personData,
                                    national: e.target.value,
                                })
                            }}
                        />
                    </label>

                    <label>
                        <span>Organization:</span>

                        <input
                            type="text"
                            className={styles['person-field']}
                            value={personData.organization}
                            onChange={e => {
                                setPersonData({
                                    ...personData,
                                    organization: e.target.value,
                                })
                            }}
                        />
                    </label>

                    <label>
                        <span>Status:</span>

                        <input
                            type="text"
                            className={styles['person-field']}
                            value={personData.status}
                            onChange={e => {
                                setPersonData({
                                    ...personData,
                                    status: e.target.value,
                                })
                            }}
                        />
                    </label>

                    <label>
                        <span>Comments:</span>

                        <input
                            type="text"
                            className={styles['person-field']}
                            value={personData.comments}
                            onChange={e => {
                                setPersonData({
                                    ...personData,
                                    comments: e.target.value,
                                })
                            }}
                        />
                    </label>

                    <label>
                        <span>Dues Due:</span>

                        <input
                            type="date"
                            className={styles['person-field']}
                            value={personData.dueDate}
                            onChange={e => {
                                setPersonData({
                                    ...personData,
                                    dueDate: e.target.value,
                                })
                            }}
                        />
                    </label>
                    </Col>

                    
                </Row>
                   

                   
                    <div className="buttons">
                        {!showSubmit && (
                            <>
                                <button
                                    onClick={areYouSure}
                                    className={styles.submit}
                                >
                                    Submit This Record!
                                </button>

                                <button
                                    onClick={handleClose}
                                    className={styles.submit}
                                >
                                    Close This Record Without Submitting
                                </button>
                            </>
                        )}

                        {showSubmit && (
                            <div className={styles['are-you-sure']}>
                                <p>
                                    Are you sure you want to submit this record?
                                </p>
                                <button className={styles.submit}>
                                    Yes, Submit!
                                </button>
                                <button
                                    onClick={notSure}
                                    className={styles.submit}
                                >
                                    No, Cancel!
                                </button>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}
