
//bootstrap:
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Nav from 'react-bootstrap/Nav'
import Image from 'react-bootstrap/Image'

export default function Footer() {
  

    return (
        <footer className="">
            <Container fluid className="">
            
                    <Row className="bg-primary">
                        <Col xs={9}></Col>
                    
                  <Col >
                  <p className='text-white'>©2022 sf gray panthers</p>
          
                 {/* <a href="/join" className='text-white'>would you like to receive emails?</a> */}
                  </Col>
                  

                 


                        
                    </Row>

      
                 

                
       
            </Container>
        </footer>
    )
}

   {/* </Col> */}
                    {/* <Col
                        lg={1}
                        // style={{maxHeight: '16rem'}}
                        className="foot-name py-4 px-4"
                    > */}
                    {/* <h6 className='display-5'>SF Gray Panthers</h6> */}

                    {/* <address className="">
                            <h6>222 13th Ave San Francisco, CA 94122 </h6>
                        </address> */}
                    {/* <h6>
                            <small>(415) 867-5309</small>
                        </h6> */}

                    {/* <p className='fs-4 mt-3'>Age and Youth In Action!</p> */}

                    {/* <p className='fs-4'>admin@sfgraypanthers.org</p> */}
                    {/* </Col> */}
