import { useState, useEffect } from "react"
import { useParams, useLocation } from "react-router-dom"
import sanityClient from "../client"
import { clientForPreview } from "../client"
import imageUrlBuilder from "@sanity/image-url"
import BlockContent from "@sanity/block-content-to-react"
import MyNavbar from "../components/MyNavbar"
import Footer from "../components/Footer"

//react bootstrap:
import { Container, Image, Row, Col } from "react-bootstrap"

const builder = imageUrlBuilder(sanityClient)
function urlFor(source) {
    return builder.image(source)
}
//this is our setup for image-url which we imported from sanity

// https://www.youtube.com/watch?v=9ipNrJlIj_I&list=PLRzQpWc3zNPmzABntnD4myIsTlkbIxfuF&index=3
const serializers = {
    marks: {
        internalLink: ({ children, mark }) => <a href={mark.url}>{children}</a>,
        link: ({ children, mark }) =>
            mark.blank ? (
                <a href={mark.href} target="_blank" rel="noopener noreferrer">
                    {children}
                </a> //open in a new window!
            ) : (
                <a href={mark.href}>{children}</a>
            ),
    },
}

export default function ChildPage() {
    const [pageData, setPageData] = useState(null)
    const { slug } = useParams()
    const { pathname } = useLocation()

    useEffect(() => {
        let client = sanityClient
        if (pathname.startsWith("/preview")) {
            client = clientForPreview
        } //if our url begins with "preview," then we'll use the preview client! cool!

        client
            .fetch(
                `*[slug.current == $slug]{
                title,
                subTitle,
                slug,
                body[]{
                    ...,
                    markDefs[]{
                        ...,
                        _type == "internalLink" => {
                            ...,
                            "slug": @.item->slug,
                            "url": "https://panther-gray.web.app/child/" + @.item->slug.current
                        }
                    }
                },
                image{
                  asset->{
                      _id,
                      url
                  },
              },
            }
            `,
                { slug }
            )
            .then(data => setPageData(data[0]))
            .catch(console.error)
    }, [slug, pathname])

    if (!pageData) return <div>loading...</div>

    return (
        <>
            <MyNavbar />

            <Container
                fluid
                className="color-secondary border-primary border-bottom border-5 rounded-0"
                style={{
                    backgroundImage: `linear-gradient(rgb(0, 0, 0, 0.9), rgb(255, 253, 218, 0.4))`,
                }}
            >
                <Row className="flex justify-content-center p-4 mx-xl-0">
                    <Col
                        md={10}
                        xl={6}
                        className="mt-md-5 p-5 color-secondary text-break "
                    >
                        <h1 className="text-center display-5">
                            {pageData && pageData.title}
                        </h1>
                    </Col>
                </Row>

                <Row className="flex justify-content-center my-lg-5 my-3 pb-5">
                    <Col md={12} xl={8}>
                        <section className=" color-primary p-5 m-1">
                            <article className="subpage-article px-md-5">
                                <Row className="justify-content-center">
                                    <Col md={8}>
                                        <h2 className="text-center my-3 display-6">
                                            {pageData && pageData.subTitle}
                                        </h2>
                                    </Col>
                                </Row>

                                <Row className="my-5">
                                    <BlockContent
                                        serializers={serializers}
                                        blocks={pageData.body}
                                        projectId={
                                            sanityClient.clientConfig.projectId
                                        }
                                        dataset={
                                            sanityClient.clientConfig.dataset
                                        }
                                    />
                                </Row>
                            </article>
               
                        </section>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    )
}
