import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import sanityClient from "../client"
import imageUrlBuilder from "@sanity/image-url"
import BlockContent from "@sanity/block-content-to-react"
import MyNavbar from "../components/MyNavbar"
import EmailForm from "../components/EmailForm"
import Footer from "../components/Footer"

//react bootstrap:
import { Container, Image, Row, Col } from "react-bootstrap"

const builder = imageUrlBuilder(sanityClient)
function urlFor(source) {
    return builder.image(source)
}
//this is our setup for image-url which we imported from sanity

export default function ActionAlerts() {
    const [pageData, setPageData] = useState(null)
    const { slug } = useParams()

    return (
        <div className="bg-primary">
            <MyNavbar />

            <Container
                fluid
                className=""
                style={{
                    backgroundImage: `linear-gradient(rgb(109, 149, 180, 0.01), rgb(255, 253, 218, 0.4))`,
                }}
            >
                <Row className="text-center justify-content-center align-items-start flex-wrap px-md-5">
                    <Col className="" lg={5}>
                        <div className="bg-warning text-center my-3 rounded px-2 pt-4">
                            <h1 className="display-2">Prowl & Growl</h1>
                            <h5 className="px-5 p-3">
                                Action Alerts from the Gray Panthers of San
                                Francisco
                            </h5>
                        </div>

                       
                    </Col>

                    <Col
                        xs={12}
                        md={12}
                        lg={7}
                        className="justify-content-center "
                    >

                        <Row className='justify-content-end'>

                            <Col xs={9}>


            
                            <div className="color-secondary p-2 p-md-3 p-lg-5 mt-lg-5 text-start">
                                
                                <a href="https://docs.google.com/document/d/1HgxkIxparHFSFm76emlh2vwuq7K4nmZ3EOtFq9qfc9I/">
                                
                                Having trouble seeing the embedded Google
                                    Doc on this page? Here's a direct link.
                                </a>
                            </div>
                            </Col>
                        </Row>
            

                        <iframe
                            className="p-md-2 google-doc my-3 mt-lg-5"
                            style={{ minHeight: "128rem", overflow: "scroll" }}
                            width="100%"
                            title="Embedded Google Doc, Gray Panthers Prowl and Growl Newsletter"
                            src="https://docs.google.com/document/d/e/2PACX-1vTlnJeJhrrml2d6wXE0VhboL4kpNMaO3XKwx6-EK_Vz4t5omNqTzLi0cg1jl0P6dkKL1dX3I9W2oUzu/pub?embedded=true"
                        ></iframe>
                    </Col>

                
                </Row>
              
            </Container>

            <Footer />
        </div>
    )
}
