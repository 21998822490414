import MyNavBar from "../components/MyNavbar"
import Footer from "../components/Footer"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"

export default function ThankYou() {
    return (
        <>
            <MyNavBar />
            <Container className="color-tertiary m-5 p-5">
                <Row className="color-secondary">
                    <Col>
                        <h4 className="m-5  display-3">
                            Thank you for joining the San Francisco Gray
                            Panthers!
                        </h4>
                        <h5 className="m-5 display-4">
                            Your support makes a difference.
                        </h5>
                        <p className="m-5">
                            Please be on the lookout for a confirmation email
                            (check your spam folder, sometimes our
                            communications get caught in these). If you don't
                            hear from us at all, it means something went wrong.
                            Let us know at admin@sfgraypanthers.org and we'll
                            see about remedying it.
                        </p>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    )
}
