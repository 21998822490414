import { useState, useEffect } from "react"

import { useCollection } from "../hooks/useCollection"

import PayPalModal from "./PayPalModal"

import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
// import InputGroup from "react-bootstrap/InputGroup"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
// import Container from "react-bootstrap/Container"

export default function RenewGrayPanthers({ id }) {
    const [personData, setPersonData] = useState({ id: id })
    //grab the document id from props, this is the firestore_id of the person we're renewing

    //WE'RE ALREADY SETTING A NEW DUE DATE IN THE PAYPAL MODAL COMPONENT! YAY!
    const [showPopup, setShowPopup] = useState(false)
    const [showPayPal, setShowPaypal] = useState(false)
    const [notes, setNotes] = useState("")
    const [expired, setExpired] = useState(true)

    // 2/27/23 I'm commenting out the below, because I'm disabling read requests for unauthenticated users.
    // const { documents, error } = useCollection("people", ["__name__", "==", id])
    //per stackoverflow... __name__ is how you get to a document's firestore ID using this type of query
    //this gives us ONLY the document with the firestore_id that matches the url string

    const changeNotes = e => {
        setNotes(e.target.value)
        // console.log("changing notes! ", e.target.value)
    }

    const infoLooksGood = () => {
        //FUNCTION THAT ACTUALLY FIRES SUBMIT
        setPersonData({
            id: personData.id,
            memberNotes: notes,
            signedUpViaWebsite: true,
        })
        // 2/24/23: added signedUpViaWebsite to satisfy firebase rules
        // 2/27/23 also added signedUpViaWebsite to the useFirestore dispatch
        //all we really need to pass to the PayPalModal component is their id. The component will generate a due date for them
        //we're also gonna pass "notes", in case they have something to say, or a change of address or whatever
        setShowPaypal(true)
    }

    const handleShow = e => {
        //THIS FUNCTION SHOULDNT ACTUALLY SUBMIT< IT SHOULD SHOW AN ARE YOU SURE DIALOG
        e.preventDefault()
        setShowPopup(true)
    }

    const handleClose = () => setShowPopup(false)

    useEffect(() => {
        //every time notes changes, add whatever's in it to the personData object to be passed along to PayPalModal
        // console.log('notes or error changed, useEffect fired!')
        setPersonData(prevState => ({ ...prevState, status: notes || "" }))
        //don't forget these parenthesis! Javascript doesn't understand your object unless you use em. Stupid arrow function.

        // if (error) {
        //     console.log("theres an error!", error)
        // }
    }, [notes])

    // 2/27/23 I'm commenting out the below, because I'm disabling read requests for unauthenticated users.
    // useEffect(() => {
    //     //we want to compare the due date to today and determine if we need to do a little setExpired(true)
    //     //note: if the person's record NO LONGER EXISTS, this page cannot be displayed, because there will be no "documents"
    //     documents && documents[0].dueDate.toDate() > new Date()
    //         ? setExpired(false)
    //         : setExpired(true)
    // }, [documents])

    return (
        <div className="color-secondary py-lg-4">
            <Modal
                show={showPopup}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className="color-tertiary ">
                    <Modal.Title
                        className="text-white"
                        style={{ textShadow: "2px 2px 1px black" }}
                    >
                        Please enter your current information,{" "}
                        {personData.firstName} {personData.lastName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body></Modal.Body>
                <Modal.Footer>
                    {showPayPal ? (
                        <PayPalModal personData={personData} renewal={true} />
                    ) : (
                        <>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                                className="rounded"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                className="rounded"
                                onClick={infoLooksGood}
                            >
                                Submit
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>
            <h5 className="fs-3">Renew Your Membership</h5>

            {/* grab the person's info, looking them up by their firestore uid */}
            {/* map is redundant because "documents" is only ever going to be ONE record */}

            <h6>member id: {id}</h6>

            {/* {documents ? (
                documents.map(person => (
                    <div key={person.id}>
                        {person.firstName && person.lastName && (
                            <h1>
                                {person.firstName} {person.lastName}
                            </h1>
                        )}

                        {person.organization && <h1>{person.organization}</h1>}
                    </div>
                ))
            ) : (
                <h6 className="">`Member ID: ${id}`</h6>
            )} */}

            <div className="m-5 p-2">
                <p>
                    You can renew your SFGP membership right here on our
                    website.
                </p>
                {showPayPal ? (
                    <PayPalModal
                        personData={personData}
                        renewal={true}
                        // memberNotes={notes}
                    />
                ) : (
                    <>
                        <Row className="mt-5 ">
                            <Col>
                                <label htmlFor="">
                                    Is there anything you'd like us to know?
                                    <Form.Control
                                        as="textarea"
                                        aria-label="changes to your contact info"
                                        onChange={changeNotes}
                                        placeholder="change of name or address, comments, suggestions etc"
                                        className="my-4"
                                        style={{ minHeight: "12rem" }}
                                    />
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    variant="primary"
                                    className="rounded p-4"
                                    onClick={infoLooksGood}
                                >
                                    Pay My Dues
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}

                <div className="mt-5">
                    <a href="/contact">
                        Having trouble paying? Click here to contact us.
                    </a>
                </div>
            </div>

            {/* {expired ? (
                <div className='m-5 p-2'>
                <p>Your SFGP membership has expired. You can renew right here on our website.</p>
                {showPayPal ? (
                <PayPalModal
                    personData={personData}
                    renewal={true}
                    // memberNotes={notes}
                />
            ) : (
                <>
                   

                    <Row className='mt-5 ' >
                        <Col
                        >
                            <label htmlFor="">
                                Is there anything you'd like us to know?
                                <Form.Control
                                    as="textarea"
                                    aria-label="changes to your contact info"
                                    onChange={changeNotes}
                                    placeholder="change of name or address, comments, suggestions etc"
                                    className='my-4'
                                    style={{minHeight: '12rem'}}
                                />
                            </label>
                        </Col>

                     
                          
                     
                    </Row>
                    <Row>
                        <Col >


                    <Button
                                variant="primary"
                                className="rounded p-4"
                                onClick={infoLooksGood}
                                
                                >
                                Pay My Dues
                            </Button>
                                </Col>
                    </Row>
                </>
            )}

            <div className="mt-5">

                <a href="/contact">Having trouble paying?</a>
            </div>
                </div>
            ) : (
                <>
                <p>Your membership is current. Thank you for your support.</p>
                <a href="/contact">technical problems?</a>
                </>
            )} */}

            {/* <a href="/contact">experiencing technical problems?</a> */}
        </div>
    )
}
