import { useState, useRef, createRef } from "react"
import { useFirestore } from "../hooks/useFirestore"
import { useAddressFormat } from "../hooks/useAddressFormat"

import { timestamp } from "../firebase/config"

import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

export default function ModalPersonInput({
    //YO im switching thisPerson to be the person passed down, and person is the NEW value as defined by these input fields!
    thisPerson,
    showInput,
    handleClose,
    showDelete,
    setShowDelete,
    setShowSubmit,
    showSubmit,
}) {
    //state
    const [person, setPerson] = useState(thisPerson)

    //hooks
    const { updateDocument, deleteDocument, response } = useFirestore("people")

    console.log(
        'ModalPersonInput.js: our "person" stored in state is: ',
        person
    )

    const contactInfo = [
        ...useAddressFormat(person),
        person.email || "<no email>",
        person.phone || "<no phone number>",
        `organization: ${person.organization || "<not specified>"}`,
        `national: ${person.national || "<not specified>"}`,
        `status: ${person.status || "<not specified>"}`,
        `comments: ${person.comments || "<no comments>"} `,
    ]
    //

    const handleSubmit = async e => {
        e.preventDefault() //dont reload the page!

        const dataToUpdate = person
        updateDocument(thisPerson.id, dataToUpdate) //id won't change... we could use thisPerson OR person
        console.log("thisPerson.id: ", thisPerson.id)
        handleClose()
    }

    const areYouSure = e => {
        e.preventDefault()
        setShowSubmit(true)
    }
    const notSure = e => {
        e.preventDefault()
        setShowSubmit(false)
    }

    const handleDateChange = e => {
        setPerson({
            ...person,
            dueDate: timestamp.fromDate(new Date(e.target.value)),
        })

        // console.log(
        //     timestamp.fromDate(new Date(person.dueDate))
        //     // new Date(person.dueDate).getTime()

        //     // .toDate()
        //     // .toISOString()
        //     // .slice(0, 19)
        //     // .replace("T", " "))
        // )
    }
    return (
        <>
            <Modal show={showInput} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {person.firstName
                            ? person.firstName
                            : "<no first name>"}{" "}
                        {person.lastName ? person.lastName : "<no last name>"}
                        <p>{person.organization}</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="color-secondary">
                    <Row>
                        <div className="">
                            <p>Edit this person's record?</p>
                        </div>
                    </Row>
                    <Form
                        onSubmit={handleSubmit}
                        className="color-tertiary p-5"
                    >
                        <Row className="justify-content-center">
                            <Col md={6} className="">
                                <Form.Group>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.firstName}
                                        value={person.firstName}
                                        onChange={e => {
                                            console.log("this person: ", person)
                                            setPerson({
                                                ...person,
                                                firstName: e.target.value,
                                            })
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.lastName}
                                        value={person.lastName}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                lastName: e.target.value,
                                            })
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.email}
                                        value={person.email}
                                        onChange={e => {
                                            console.log("this person: ", person)
                                            setPerson({
                                                ...person,
                                                email: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.phone}
                                        value={person.phone}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                phone: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Zip Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.zip}
                                        value={person.zip}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                zip: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Street Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.address}
                                        value={person.address}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                address: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Apartment</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.apt}
                                        value={person.apt}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                apt: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.city}
                                        value={person.city}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                city: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>State</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.state}
                                        value={person.state}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                state: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        Due Date (changing this may trigger an
                                        automatic email to this person)
                                        {/* {
                                            person.dueDate
                                                .toDate()
                                                .toISOString()
                                                .slice(0, 11)
                                                .replace("T", " ")} */}
                                    </Form.Label>

                                    <Form.Control
                                        type="date"
                                        onChange={handleDateChange}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>

                            <Col className="color-primary m-3 p-3">
                                <Form.Group>
                                    <Form.Label>National (Y/N)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.national}
                                        value={person.national}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                national: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Organization</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.organization}
                                        value={person.organization}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                organization: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.status}
                                        value={person.status}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                status: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Comments</Form.Label>
                                    <Form.Control
                                        // type="text"
                                        as="textarea"
                                        placeholder={person.comments}
                                        value={person.comments}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                comments: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>

                            {!showSubmit && (
                                <Button
                                    className="mt-4"
                                    variant="primary"
                                    onClick={areYouSure}
                                >
                                    Submit Changes To This Record
                                </Button>
                            )}

                            {showSubmit && (
                                <section
                                    style={{
                                        backgroundColor: "var(--color-link)",
                                    }}
                                    className="p-5 mt-5"
                                >
                                    <h6 className="display-6 text-white">
                                        Are you sure you want to submit this
                                        record?
                                    </h6>
                                    <Row className="my-3">
                                        <Col>
                                            <Row className="color-secondary p-4 ">
                                                <h6>
                                                    RECORD YOU'RE ABOUT TO
                                                    SUBMIT:
                                                </h6>
                                                <hr />
                                                {contactInfo.map(line => (
                                                    <p key={line}>{line}</p>
                                                ))}
                                                <>
                                                    <p>
                                                        DUE DATE:{" "}
                                                        {person.dueDate &&
                                                            person.dueDate
                                                                .toDate()
                                                                .toISOString()
                                                                .slice(0, 11)
                                                                .replace(
                                                                    "T",
                                                                    " "
                                                                )}
                                                    </p>
                                                </>
                                            </Row>
                                        </Col>
                                        <Col className="mx-3">
                                            <Button
                                                className="mx-2 my-5 text-black rounded     fs-2  "
                                                variant="warning"
                                                size="lg"
                                                type="submit"
                                                onClick={handleSubmit}
                                            >
                                                Yes, Everything Looks Good!
                                            </Button>

                                            <aside className="mb-5">
                                                <p className="text-white">
                                                    TIP: most edits made here
                                                    WILL NOT carry over to the
                                                    Sender email system. Please
                                                    check the matching record in
                                                    Sender after making changes.
                                                </p>
                                            </aside>
                                            <Button
                                                className="mx-2 rounded fs-3"
                                                variant="primary"
                                                size="lg"
                                                onClick={notSure}
                                            >
                                                No, Cancel Without Making Any
                                                Changes!
                                            </Button>
                                        </Col>
                                    </Row>
                                </section>
                            )}
                        </Row>
                    </Form>
                    <Button
                        className="mt-4"
                        variant="outline-danger"
                        onClick={() => {
                            setShowDelete(true)
                            // console.log('tried to delete')
                        }}
                    >
                        Delete This Record
                    </Button>
                    {showDelete && (
                        <section className="flex-column justify-content-center align-items-center mt-5">
                            <h5>
                                Are you sure you want to DELETE THIS RECORD?
                            </h5>
                            <Button
                                className="btn-danger m-3"
                                onClick={() => {
                                    deleteDocument(person.id)
                                    handleClose()
                                }}
                            >
                                Yes, delete {person.firstName} {person.lastName}
                            </Button>
                            <Button
                                className="btn-info m-3"
                                onClick={() => setShowDelete(false)}
                            >
                                No, Never Mind!
                            </Button>
                        </section>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
