//SUBPAGES ARE ACTIVISM PAGES
import { useState, useEffect } from "react"
import { useParams, useLocation } from "react-router-dom"
import sanityClient from "../client"
import { clientForPreview } from "../client"
import imageUrlBuilder from "@sanity/image-url"
import BlockContent from "@sanity/block-content-to-react"
import MyNavbar from "../components/MyNavbar"
import Footer from "../components/Footer"
import EmailForm from "../components/EmailForm"

//react bootstrap:
import { Container, Image, Row, Col } from "react-bootstrap"

const builder = imageUrlBuilder(sanityClient)
function urlFor(source) {
    return builder.image(source)
}
//this is our setup for image-url which we imported from sanity

// https://www.youtube.com/watch?v=9ipNrJlIj_I&list=PLRzQpWc3zNPmzABntnD4myIsTlkbIxfuF&index=3
const serializers = {
    marks: {
        internalLink: ({ children, mark }) => <a href={mark.url}>{children}</a>,
        link: ({ children, mark }) =>
            mark.blank ? (
                <a href={mark.href} target="_blank" rel="noopener noreferrer">
                    {children}
                </a> //open in a new window!
            ) : (
                <a href={mark.href}>{children}</a>
            ),
    },
}

export default function SubPage() {
    const [pageData, setPageData] = useState(null)
    const { slug } = useParams()
    const { pathname } = useLocation()
    console.log("location?!?! ", useLocation())
    console.log(pathname)

    useEffect(() => {
        // https://youtu.be/9ipNrJlIj_I?t=711 for body projections!
        //HEY! IF YOU HAVE A TRAILING COMMA IN YOUR GROQ QUERY? IT WONT FUCKING WORK!

        if (pathname.startsWith("/preview")) {
            clientForPreview
                .fetch(
                    `*[slug.current == $slug]{
              ...,
              image,
                body[]{
                    ...,
                    markDefs[]{
                        ...,
                        _type == "internalLink" => {
                            ...,
                            "slug": @.item->slug,
                            "url": "https://panther-gray.web.app/child/" + @.item->slug.current
                        }
                    }
                },
               
              }
            
            `,
                    { slug }
                )
                .then(data => setPageData(data[0]))
                .catch(console.error)
        } else {
            sanityClient
                .fetch(
                    `*[slug.current == $slug]{
              ...,
              image,
                body[]{
                    ...,
                    markDefs[]{
                        ...,
                        _type == "internalLink" => {
                            ...,
                            "slug": @.item->slug,
                            "url": "https://panther-gray.web.app/child/" + @.item->slug.current
                        }
                    }
                },
               
              }
            
            `,
                    { slug }
                )
                .then(data => setPageData(data[0]))
                .catch(console.error)
        }
    }, [slug, pathname])

    if (!pageData) return <div>loading...</div>

    return (
        <>
            <MyNavbar />

            <Container
                fluid
                className="border-bottom bg-primary border-5 border-primary"
                style={{
                    backgroundImage: `linear-gradient(rgb(109, 149, 180, 0.01), rgb(255, 253, 218, 0.4))`,
                }}
            >
                <Row className="justify-content-center align-items-center">
                    <Col md={11} lg={6} className="p-4 my-4 color-primary">
                        <h1 className="text-center display-5 subpage-title">
                            {" "}
                            {pageData && pageData.title}
                        </h1>
                    </Col>

                    <Col
                        xs={10}
                        md={4}
                        className="text-center color-secondary mx-5 my-3 px-3 py-4 pb-0 "
                    >
                        <h5 className=" fs-4">
                            {pageData && pageData.subTitle}
                        </h5>
                    </Col>
                    {pageData.image && (
                        <Col md={5} className="mt-4">
                            <div className="color-secondary  p-2 m-3 text-center">
                                <Image
                                    fluid
                                    src={urlFor(pageData.image).url()}
                                    alt="main photo"
                                    className=""
                                />
                            </div>
                        </Col>
                    )}
                </Row>

                <Row className="flex justify-content-center pb-5">
                    <Col md={12} xl={9} xxl={8}>
                        <section className=" color-secondary p-5 mt-md-5 mt-3 ">
                            <article className="subpage-article">
                                <BlockContent
                                    className="p-md-5"
                                    serializers={serializers}
                                    blocks={pageData.body}
                                    projectId={
                                        sanityClient.clientConfig.projectId
                                    }
                                    dataset={sanityClient.clientConfig.dataset}
                                />
                            </article>
                        </section>
                    </Col>
                    <Col md={12} lg={10} className="p-0 mt-5"></Col>
                </Row>
            </Container>

            <Footer />
        </>
    )
}
