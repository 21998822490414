//stupid fucking date object: https://stackoverflow.com/questions/8609261/how-to-determine-one-year-from-now-in-javascript

import {
    useState,
    useEffect,
    useRef,
    useMemo,
    useCallback,
    createRef,
} from "react"

import ReCAPTCHA  from "react-google-recaptcha"

import PayPalModal from "./PayPalModal"

import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"

export default function JoinGrayPanthers() {
    const [personData, setPersonData] = useState({
        firstName: "",
        lastName: "",
        address: "",
        apt: "",
        city: "",
        state: "",
        zip: "",
        email: "",
        phone: "",
        national: "",
        organization: "",
        status: "",
        comments: "",
        //createdAt is already defined in the useFirestore hook! great!
        dueDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)), //we need a date one year in the future

        signedUpViaWebsite: true, //we need a new field to show the person signed themself up!,
    })

    const [showPopup, setShowPopup] = useState(false)
    const [showPayPal, setShowPaypal] = useState(false)

    const recaptchaRef = useRef()
    // https://github.com/dozoisch/react-google-recaptcha
    // console.log(recaptchaRef)

    const infoLooksGood = () => {
        setShowPaypal(true)
    }

    const handleShow = async (e) => {
        // //this function pops up the "are you sure" dialog
        console.log("fired!")

        //copying from modalpersoninput.js:
        e.preventDefault() //dont reload the page!
        const token = await recaptchaRef.current.executeAsync()
        if (token) {
            console.log("token is... ", token)
            setShowPopup(true)
        }

        if (!token) {
            console.log("no token")
        }
    }

    const handleClose = () => setShowPopup(false)

    return (
        <>
            <Modal
                show={showPopup}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton className="color-tertiary ">
                    <Modal.Title
                        className="text-white"
                        style={{ textShadow: "2px 2px 1px black" }}
                    >
                        Do we have your information correct,{" "}
                        {personData.firstName}?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This is how your record will appear in our database:</p>
                    <hr />
                    <div className="color-secondary p-3">
                        {/* <p>{JSON.stringify(personData)}</p> */}
                        <p>
                            name: {personData.firstName} {personData.lastName}{" "}
                        </p>
                        <p> email: {personData.email} </p>
                        <p>
                            address:{" "}
                            {personData.address || <em>none provided</em>}{" "}
                        </p>

                        <p>
                            {" "}
                            {personData.apt && "apt #: "} {personData.apt}
                        </p>
                        {personData.city && personData.state && (
                            <p>
                                city/state: {personData.city},{" "}
                                {personData.state}
                            </p>
                        )}
                        <p>
                            phone: {personData.phone || <em>none provided </em>}
                        </p>
                        <p>zip code: {personData.zip}</p>
                    </div>
                    <hr />
                    <p>
                        <strong>
                            If this is correct, click the CONFIRM button to pay
                            your first dues as a member of SFGP!
                        </strong>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    {showPayPal ? (
                        <PayPalModal personData={personData} renewal={false} />
                    ) : (
                        <>
                            <Button
                                variant="secondary"
                                onClick={handleClose}
                                className="rounded"
                            >
                                Go Back
                            </Button>
                            <Button
                                variant="primary"
                                className="rounded"
                                onClick={infoLooksGood}
                            >
                                Confirm
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>

            <Container>
                <Row className="">
                    <Col md={4} className="mb-5">
                        <Row>
                            <section className="color-secondary p-3">
                                <h5 className="mt-2">BECOME A MEMBER</h5>

                                <p>
                                    Dues are $30/year (more if you can, less if
                                    you can't)
                                </p>
                                <p>
                                    We are a 501c(3) organization; EIN
                                    94-2514721.
                                </p>
                                <hr />
                            </section>
                        </Row>

                        <aside className="my-4 color-secondary p-3">
                            <h5 className="">
                                <strong>Want to Join By Mail?</strong>
                            </h5>
                            <p>
                                Please mail your check and the requested
                                information to:
                            </p>

                            <p>Gray Panthers of San Francisco</p>
                            <p>1742 Fell St.</p>
                            <p>San Francisco CA 94117-2027</p>
                        </aside>
                    </Col>

                    <Col md={7} className="color-secondary p-4 mx-lg-4">
                        <Form onSubmit={handleShow}>
                            <Row className="">
                                <Col xs={12} lg={6}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicFirstName"
                                    >
                                        <Form.Label>
                                            First Name <em>(required)</em>
                                        </Form.Label>
                                        <Form.Control
                                            className="bg-border border-4 border-black"
                                            type="text"
                                            required
                                            value={personData.firstName}
                                            onChange={e => {
                                                setPersonData({
                                                    ...personData,
                                                    firstName: e.target.value,
                                                })
                                            }}
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicLastName"
                                    >
                                        <Form.Label>
                                            Last Name <em>(required)</em>
                                        </Form.Label>
                                        <Form.Control
                                            className="bg-border border-4 border-black"
                                            type="text"
                                            required
                                            value={personData.lastName}
                                            onChange={e => {
                                                setPersonData({
                                                    ...personData,
                                                    lastName: e.target.value,
                                                })
                                            }}
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicZip"
                                    >
                                        <Form.Label>
                                            Zip Code <em>(required)</em>
                                        </Form.Label>
                                        <Form.Control
                                            className="bg-border border-4 border-black"
                                            type="text"
                                            required
                                            value={personData.zip}
                                            onChange={e => {
                                                setPersonData({
                                                    ...personData,
                                                    zip: e.target.value,
                                                })
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>
                                            Email address <em>(required)</em>
                                        </Form.Label>
                                        <Form.Control
                                            className="bg-border border-4 border-black"
                                            type="email"
                                            required
                                            onChange={e => {
                                                setPersonData({
                                                    ...personData,
                                                    email: e.target.value,
                                                })
                                            }}
                                        />
                                        <Form.Text className="text-muted">
                                            We will never share your email with
                                            anyone else.
                                        </Form.Text>
                                    </Form.Group>

                                    {/* HERES THE TRICKY PHONE NUMBER SECTION! */}
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicPhone"
                                    >
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            className="bg-border border-4 border-black"
                                            type="text"
                                            // type="tel"
                                            // pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                                            // placeholder='4155550169'
                                            value={personData.phone}
                                            onChange={e => {
                                                setPersonData({
                                                    ...personData,
                                                    phone: e.target.value,
                                                })
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicAddress"
                                    >
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control
                                            className="bg-border border-4 border-black"
                                            type="text"
                                            value={personData.address}
                                            onChange={e => {
                                                setPersonData({
                                                    ...personData,
                                                    address: e.target.value,
                                                })
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicApt"
                                    >
                                        <Form.Label>Apartment #</Form.Label>
                                        <Form.Control
                                            className="bg-border border-4 border-black"
                                            type="text"
                                            value={personData.apt}
                                            onChange={e => {
                                                setPersonData({
                                                    ...personData,
                                                    apt: e.target.value,
                                                })
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicCity"
                                    >
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            className="bg-border border-4 border-black"
                                            type="text"
                                            value={personData.city}
                                            onChange={e => {
                                                setPersonData({
                                                    ...personData,
                                                    city: e.target.value,
                                                })
                                            }}
                                        />
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicState"
                                    >
                                        <Form.Label>State</Form.Label>
                                        <Form.Control
                                            className="bg-border border-4 border-black"
                                            type="text"
                                            value={personData.state}
                                            onChange={e => {
                                                setPersonData({
                                                    ...personData,
                                                    state: e.target.value,
                                                })
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <ReCAPTCHA
                                sitekey="6LdN3gMjAAAAABHScDVs1z6IjoyQcNlSP62JX5oQ"
                                size="invisible"
                                ref={recaptchaRef}
                            />
                            <Row className="justify-content-center">
                                <Col md={8}>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        style={{ fontSize: "2rem" }}
                                        className="mt-5 p-5 rounded"
                                    >
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
