import { useEffect, useState } from "react"
import BlockContent from "@sanity/block-content-to-react"
import { useLocation } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap"

import sanityClient from "../client"
import { clientForPreview } from "../client"
import MyNavbar from "../components/MyNavbar"
import Footer from "../components/Footer"

// https://www.youtube.com/watch?v=9ipNrJlIj_I&list=PLRzQpWc3zNPmzABntnD4myIsTlkbIxfuF&index=3
const serializers = {
    marks: {
        internalLink: ({ children, mark }) => <a href={mark.url}>{children}</a>,
        link: ({ children, mark }) =>
            mark.blank ? (
                <a href={mark.href} target="_blank" rel="noopener noreferrer">
                    {children}
                </a> //open in a new window!
            ) : (
                <a href={mark.href}>{children}</a>
            ),
    },
}

export default function AboutUs() {
    const [pageData, setPageData] = useState(null)
    const { pathname } = useLocation()

    useEffect(() => {
        let client = sanityClient
        if (pathname.startsWith("/preview")) {
            console.log("pathname starts with preview!")
            client = clientForPreview
        } //if our url begins with "preview," then we'll use the preview client! cool!

        client
            .fetch(
                // I CHANGED THE QUERY! used to be _type, now it's _id... since we technically have more than one aboutUs saved in Sanity now since I messed with Structure Builder, we want the one with the _id 'aboutUs'
                `*[_id == 'aboutUs']{
            body[]{
                ...,
                markDefs[]{
                    ...,
                    _type == "internalLink" => {
                        ...,
                        "slug": @.item->slug,
                        "url": "https://panther-gray.web.app/child/" + @.item->slug.current
                    }
                }
            },
            }`
            )
            .then(data => setPageData(data[0]))
            .catch(console.error)
    }, [pathname])

    if (!pageData) {
        return <h1>Loading...</h1>
    }
    return (
        <>
            <MyNavbar />

            <Container
                fluid
                className="bg-primary"
                style={{
                    backgroundImage: `linear-gradient(rgb(109, 149, 180, 0.01), rgb(255, 253, 218, 0.4))`,
                }}
            >
                <Row className="justify-content-center">
                    <Col lg={6} className="p-4 mt-4 color-primary ">
                        <h1 className="text-center display-2 ">About Us</h1>
                    </Col>
                </Row>

                <Row className="flex align-items-center justify-content-center  p-md-5 py-4">
                    <Col lg={8} className="color-secondary   p-5 m-lg-2">
                        {pageData && (
                            <BlockContent
                                className="p-lg-4 m-lg-4"
                                serializers={serializers}
                                blocks={pageData.body}
                                projectId={sanityClient.clientConfig.projectId}
                                dataset={sanityClient.clientConfig.dataset}
                            />
                        )}
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    )
}
