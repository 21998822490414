import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";

export const useAuthContext = () => {
    const context = useContext(AuthContext)
    //remember: useContext is a hook that comes from React, and AuthContext is the context that we want to use!
    //so now... "context" is an object, and it's the value of AuthContext.Provider in AuthContext.js... which as you'll recall is {...state, dispatch}! so it'll have the user property as well as the dispatch function

        if (!context) {
            throw Error('useAuthContext must be inside an AuthContextProvider, jabroni!')
        }

    return context
}