// https://www.sanity.io/docs/preview-content-on-site
// https://exploringjs.com/es6/ch_modules.html

import sanityClient from "@sanity/client"

const clientForPreview = sanityClient({
    projectId: "fqm41fkb",
    dataset: "production",
    apiVersion: "2022-04-23",
    useCdn: false,
    withCredentials: true,
})

export { clientForPreview }

export default sanityClient({
    projectId: "fqm41fkb", // find this at manage.sanity.io or in your sanity.json
    dataset: "production", // this is from those question during 'sanity init'
    useCdn: true,
    apiVersion: "2022-04-23",
    // https://www.sanity.io/docs/js-client for info about API version, etc
})
