import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import sanityClient from "../client"
import BlockContent from "@sanity/block-content-to-react"

//components
import MyNavbar from "../components/MyNavbar"
import Footer from "../components/Footer"

//bootstrap components:
import { Container, Row, Col } from "react-bootstrap"

//my components:
import CardSubPageSection from "../components/CardSubPageSection"

import "../variables.css"

export default function Activism() {
    const [subPageData, setSubPageData] = useState(null)
    //this is summaries of Activism Pages that i'll put on the front page

    //then, fetch all the subpages, aka activismpages!
    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == 'subPage']{
                ...,
                body[]{
                    ...,
                    markDefs[]{
                        ...,
                        _type == "internalLink" => {
                            ...,
                            "slug": @.item->slug,
                            "url": "https://panther-gray.web.app/child/" + @.item->slug.current
                        }
                    }
                },
                image{
                    asset->{
                        _id,
                        url
                    },
                },
            }`
            )
            .then(data => {
                setSubPageData(data)

                return data
            })
            // don't forget: data comes back as an array!
            .catch(console.error)
    }, [])

    if (!subPageData) return <div>loading...</div>

    return (
        <>
            <MyNavbar />

            <Container
                fluid
                className="bg-primary"
                style={{
                    backgroundImage: `linear-gradient(rgb(255, 253, 218, 0.6), rgb(109, 149, 180, 0.9))`,
                }}
            >
                {subPageData && (
                    <section id="activism" className=" p-md-4 p-3">
                        <CardSubPageSection subPageData={subPageData} />
                    </section>
                )}
            </Container>
            <Footer />
        </>
    )
}
