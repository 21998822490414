import { useState } from "react"
import { useLogout } from "../hooks/useLogout"
import { Button } from "react-bootstrap"

//styles:
// import styles from "./Login.module.css"

export default function Logout() {

    const { logout, error, isPending } = useLogout()

    const handleLogout = e => {
        logout()
        //this function comes from our useLogout hook
    }

    return (
        <>
            <Button onClick={handleLogout}>
                log out
            </Button>
        </>
    )
}
