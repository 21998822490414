import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import sanityClient from "../client"
import { clientForPreview } from "../client"
import imageUrlBuilder from "@sanity/image-url"
import BlockContent from "@sanity/block-content-to-react"

//components
import MyNavbar from "../components/MyNavbar"
import Footer from "../components/Footer"

//bootstrap components:
import { Container, Row, Col } from "react-bootstrap"
//my components:
import CardSubPageSection from "../components/CardSubPageSection"

import "../variables.css"

const builder = imageUrlBuilder(sanityClient)
function urlFor(source) {
    return builder.image(source)
} //this is our setup for image-url which we imported from sanity

// https://www.youtube.com/watch?v=9ipNrJlIj_I&list=PLRzQpWc3zNPmzABntnD4myIsTlkbIxfuF&index=3
const serializers = {
    marks: {
        internalLink: ({ children, mark }) => <a href={mark.url}>{children}</a>,
        link: ({ children, mark }) =>
            mark.blank ? (
                <a href={mark.href} target="_blank" rel="noopener noreferrer">
                    {children}
                </a> //open in a new window!
            ) : (
                <a href={mark.href}>{children}</a>
            ),
    },
}

export default function HomePage() {
    const [homePageData, setHomePageData] = useState(null)
    // this is the data we're pulling from sanity for the Home Page
    const [subPageData, setSubPageData] = useState(null)
    //this is summaries of Activism Pages that i'll put on the front page
    const [theImage, setTheImage] = useState(null)
    const { pathname } = useLocation()

    useEffect(() => {
        if (pathname.startsWith("/preview")) {
            //if our url begins with "preview," then we'll use the preview client, and we'll fetch the document with _id drafts.homePage... which is what is created when we edit our HomePage before publishing it
            clientForPreview
                .fetch(
                    `*[_id == 'drafts.homePage']{
               
                    mainImage{
                        asset->{
                            _id,
                            url
                        },
                    },
                   update[]{
                        ...,
                        markDefs[]{
                            ...,
                            _type == "internalLink" => {
                                ...,
                                "slug": @.item->slug,
                                "url": "https://panther-gray.web.app/child/" + @.item->slug.current
                            }
                        }
                    }
                }`
                )
                .then(data => {
                    setHomePageData(data[0])
                    return data[0]
                })
                // don't forget: data comes back as an array!
                .then(theData => setTheImage(urlFor(theData.mainImage).url()))
                .catch(console.error)
        } else {
            sanityClient
                .fetch(
                    // I CHANGED THE QUERY! used to be _type, now it's _id... since we technically have more than one homePage saved in Sanity now since I messed with Structure Builder, we want the one with the _id of 'homePage'
                    `*[_id == 'homePage']{
                
                mainImage{
                    asset->{
                        _id,
                        url
                    },
                },
               update[]{
                    ...,
                    markDefs[]{
                        ...,
                        _type == "internalLink" => {
                            ...,
                            "slug": @.item->slug,
                            "url": "https://panther-gray.web.app/child/" + @.item->slug.current
                        }
                    }
                }
            }`
                )
                .then(data => {
                    setHomePageData(data[0])

                    return data[0]
                })
                // don't forget: data comes back as an array!
                .then(theData => setTheImage(urlFor(theData.mainImage).url()))
                .catch(console.error)
        }
    }, [pathname])

    //then, fetch all the subpages, aka activismpages!
    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == 'subPage']{
                ...,
                body[]{
                    ...,
                    markDefs[]{
                        ...,
                        _type == "internalLink" => {
                            ...,
                            "slug": @.item->slug,
                            "url": "https://panther-gray.web.app/child/" + @.item->slug.current
                        }
                    }
                },
                image{
                    asset->{
                        _id,
                        url
                    },
                },
            }`
            )
            .then(data => {
                setSubPageData(data)

                return data
            })
            .catch(console.error)
    }, [pathname])

    if (!homePageData) return <div>loading...</div>

    return (
        <>
            <MyNavbar />

            <Container
                fluid
                className="bg-primary"
                style={{
                    backgroundImage: `linear-gradient(rgb(255, 253, 218, 0.3), rgb(109, 149, 180, 0.9)), url(${theImage})`,
                }}
            >
                <Row
                 
                    className="flex justify-content-center"
                >
                    <Col md={8} lg={10} xl={8} className="mx-auto">
                        <section className="my-4 mx-md-2 py-4 px-3 px-md-4 color-secondary text-center border border-primary border-4">
                            <h1 className="display-4 ital">
                                San Francisco Gray Panthers
                            </h1>
                            {/* check out the ITAL class! */}
                            <h2 className="py-4 fs-1 ital">
                                "Age and Youth in Action"
                            </h2>

                            <p className="">
                                We stand for age and youth working together to
                                make the world a place where the young can look
                                forward to growing old. We believe all people
                                are entitled to certain fundamental rights...
                            </p>
                            <p>
                                The Gray Panthers are a series of
                                multi-generational local advocacy networks in
                                the United States which confront ageism and many
                                other social justice issues. The organization
                                was formed by Maggie Kuhn in response to her
                                forced retirement from the Presbyterian Church
                                at the age of 65 in 1970.
                            </p>
                        </section>
                    </Col>
                </Row>

                {/* if homePageData.update is blank, then its block content schema actually renders an array with a length of 4! So it'll never be nonexistent... this is the code that we need to run to make sure it doesn't render a yellow box when the section is blank */}
                {homePageData.update && (
                    <aside className="mt-2 pt-2 px-md-4 py-5">
                        <Row className="flex align-items-center justify-content-center">
                            <Col md={8} lg={10} xl={6}>
                                <section className="color-primary border border-primary border-4  px-md-5 px-4  pt-5">
                                    <BlockContent
                                        className=" p-lg-5 px-md-5 py-4"
                                        serializers={serializers}
                                        blocks={homePageData.update}
                                        projectId={
                                            sanityClient.clientConfig.projectId
                                        }
                                        dataset={
                                            sanityClient.clientConfig.dataset
                                        }
                                    />
                                </section>
                            </Col>
                        </Row>
                    </aside>
                )}
                {/* SECTION: OUR ORGANIZATION (subPages) */}

                {subPageData && (
                    <section id="activism" className=" p-md-4 p-3">
                        <CardSubPageSection subPageData={subPageData} />
                    </section>
                )}
            </Container>

            <Footer />
        </>
    )
}
