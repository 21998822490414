//THIS SERVES UP EITHER
//JoinGrayPanthers.js
//OR
//RenewGrayPanthers.js

import sanityClient from "../client"
import { useState } from "react"
import { Link, useParams } from "react-router-dom"
import MyNavbar from "../components/MyNavbar"
import Footer from "../components/Footer"
import JoinGrayPanthers from "../components/JoinGrayPanthers"
import RenewGrayPanthers from "../components/RenewGrayPanthers"

import { Form, Container, Row, Col, Button } from "react-bootstrap"

export default function Join() {
    const { id } = useParams()
    //this gives us just the end of the url... the member's firestore id!

    console.log("id from useParams is ", id)

    return (
        <>
            <MyNavbar />

            <Container className="align-items-center justify-content-center">
                <Row className="">
                    <header className="py-4">
                        <h1 className="mt-2 text-center display-3">
                            Get Involved
                        </h1>
                    </header>
                    <main className="">
                        <Row className="text-center justify-content-center ">
                            <Col md={11} className="color-tertiary p-md-5 pt-5">
                                {/* did we pass in an id? because if we did, we should pass it as a prop to our Renew component. if we do NOT have an id, meaning we're at this page at SITE-URL/join... then show the JOIN component*/}
                                {id ? (
                                    <RenewGrayPanthers id={id} />
                                ) : (
                                    <JoinGrayPanthers />
                                )}
                            </Col>
                        </Row>
                    </main>
                </Row>
            </Container>
            <Footer />
        </>
    )
}
