//we don't care if this component rerenders... because it doesn't make any database calls! it gets the result of a db call as its "people" prop, aka "documents" in its parent component!

import { useEffect, useState, useRef, useCallback } from "react"
// import Fuse from 'fuse.js'

import Person from "./Person"
// import PeopleFilter from './Peoplefilter'

//styles
import styles from "./People.module.css"

//BOOTSTRAP COMPONENTS
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"

export default function People({ people }) {
    const [filteredPeople, setFilteredPeople] = useState([])
    //initialize filteredPeople state to nobody, because our useEffect will just change it right away! if we don't do this, and we set it to "people," we get a weird assortment of people
    const [activePerson, setActivePerson] = useState(null)
    //we need to specify which person record is active
    const [bayAreaOnly, setBayAreaOnly] = useState(false)
    const [sortBy, setSortBy] = useState("lastName")
    //by default, sort the records by last name!
    const [sortDescending, setSortDescending] = useState(true)
    const [excludeEmpty, setExcludeEmpty] = useState(false)

    // var sorter = 'lastName'
    //here we're trying to get around the async nature of setState... so we'll use this variable to change our sort order!

    const allPeople = useRef(people).current
    // .slice(0, 11)
    //i want to store our "people" prop in an unchanging ref value! REMEMBER: YOU HAVE TO USE .current, because the ref is an object with a "current" property

    const bayAreaPeople = useRef(
        allPeople.filter(
            person =>
                person.zip &&
                person.zip.substring(0, 5) >= 94000 &&
                person.zip.substring(0, 5) <= 94999
        )
        // if the first 5 characters of the zip are inside this range, that means they're somewhere near sf, right?
    ).current

    //FUNCTIONS THAT TOGGLE OR CHANGE STATE:
    const changeActivePerson = idOfThisPerson => {
        //set the ACTIVE PERSON state to be the id of whoever you clicked!
        // console.log('fired changeActivePerson!')
        setActivePerson(idOfThisPerson)
    }

    const resetActivePerson = () => {
        // console.log('fired resetActivePerson!')
        setActivePerson(null)
    }

    const toggleBayArea = () => {
        //toggle the boolean bayAreaOnly state
        setBayAreaOnly(previousState => !previousState)
    }

    const changeSort = e => {
        setSortBy(e.target.value)
        // console.log('now we want to sort by: ', e.target.value)
    }

    const changeSortOrder = () => {
        setSortDescending(prevState => !prevState)
        //toggle the sorting order, it's either true (ascending) or false
    }

    const changeExclusion = () => {
        //toggle boolean setExcludeEmpty state
        setExcludeEmpty(prevState => !prevState)
    }

    //FUNCTIONS THAT FILTER FILTEREDPEOPLE, when you pass it in as the argument

    //HEY HEY YO! make sure to call this function on filteredPeople down in our return when we're mapping over our collection! THAT'S HOW WE FIX THE WHOLE STATE PROBLEM!

    const sorted = useCallback(
        arrayOfPeople => {
            //call sorted(anyArray) and it'll return the array again, but sorted by whatever value is in our sortBy state

            // LETS TRY USING LOCALECOMPARE INSTEAD OF JUST SORT

            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Collator/Collator
            const options = {
                sensitivity: "base",
                // Only strings that differ in base letters compare as unequal. Examples: a ≠ b, a = á, a = A.
                ignorePunctuation: true,
                numeric: true,
                // Whether numeric collation should be used, such that "1" < "2" < "10".
            }

            //if sortDescending is true, do it in descending order
            if (sortDescending) {
                return arrayOfPeople.sort((a, b) => {
                    if (!a[sortBy]) {
                        a[sortBy] = ""
                    }

                    if (!b[sortBy]) {
                        b[sortBy] = ""
                    }

                    return a[sortBy]
                        .toString()
                        .localeCompare(b[sortBy].toString(), options)
                })
            } else {
                //otherwise it'll be false, sort in descending order!
                return arrayOfPeople.sort((a, b) => {
                    if (!a[sortBy]) {
                        a[sortBy] = ""
                    }

                    if (!b[sortBy]) {
                        b[sortBy] = ""
                    }

                    return b[sortBy]
                        .toString()
                        .localeCompare(a[sortBy].toString(), options)
                })
            }
        },
        [sortBy, sortDescending]
    )

    const onlyNonEmpty = useCallback(
        arrayOfPeople => {
            //call onlyNonEmpty(anyArray) and it'll return members of that array that have a value for the key corresponding to our current sortBy state
            return arrayOfPeople.filter(person => person[sortBy])
        },
        [sortBy]
    )

    //anytime our sorting value changes, our exclusion checkbox changes, or our bayareaonly button is clicked.... do this:
    useEffect(() => {
        setFilteredPeople(prevState => sorted(prevState))
        console.log("useeffect if conditions tree fired!")

        //no matter what: sort the filteredPeople array by whatever our sortBy value is
        //there are 4 possible cases of combinations of bay and exclude:

        //bayAreaOnly and excludeEmpty:
        if (bayAreaOnly && excludeEmpty) {
            // console.log('bayAreaOnly && excludeEmpty ', length)
            setFilteredPeople(onlyNonEmpty(sorted(bayAreaPeople)))
        }

        //bayAreaOnly and !excludeEmpty:
        if (bayAreaOnly && !excludeEmpty) {
            // console.log('bayAreaOnly && !excludeEmpty ', length)
            setFilteredPeople(sorted(bayAreaPeople))
        }

        //!bayAreaOnly and excludeEmpty:
        if (!bayAreaOnly && excludeEmpty) {
            // console.log('!bayAreaOnly && excludeEmpty ', length)
            setFilteredPeople(onlyNonEmpty(sorted(allPeople)))
        }

        //!bayAreaOnly and !excludeEmpty: we're talking about ALL PEOPLE
        if (!bayAreaOnly && !excludeEmpty) {
            // console.log('!bayAreaOnly && !excludeEmpty ', length)
            setFilteredPeople(sorted(allPeople))
        }
    }, [
        sortBy,
        sorted,
        bayAreaOnly,
        bayAreaPeople,
        onlyNonEmpty,
        excludeEmpty,
        allPeople,
    ])

    return (
        <>
            <Row
                style={{
                    background:
                        "linear-gradient(90deg, rgb(244, 245, 239) 0%, rgb(30, 57, 63) 100%)",
                }}
            >
                <Col md={5}>
                    <Row className="m-3">
                        <Col className="flex-column flex-wrap m-auto">
                            {bayAreaOnly ? (
                                <Row>
                                    <Button
                                        aria-label="Show Members From Anywhere"
                                        className={styles.button}
                                        variant="primary"
                                        onClick={toggleBayArea}
                                    >
                                        Show Members From Anywhere
                                    </Button>
                                </Row>
                            ) : (
                                <Row>
                                    <Button
                                        aria-label="Show Members From Anywhere"
                                        className={styles.button}
                                        variant="primary"
                                        onClick={toggleBayArea}
                                    >
                                        Show Bay Area Only
                                    </Button>
                                </Row>
                            )}

                            {/* then, show our select menu: */}
                            <label className="my-4" htmlFor="sort">
                                Sort Records By:
                            </label>
                            <select
                                id="sort"
                                onChange={changeSort}
                                defaultValue="lastName"
                            >
                                <option value="lastName">Last Name</option>
                                <option value="firstName">First Name</option>
                                <option value="createdAt">Created At</option>
                                <option value="dueDate">Due Date</option>
                                <option value="duesPaid">
                                    Amount of Dues Last Paid
                                </option>
                                <option value="zip">Zip Code</option>
                                <option value="id">id</option>
                                <option value="city">City</option>
                                <option value="state">State</option>
                                <option value="phone">Phone</option>
                                <option value="email">Email</option>
                                <option value="national">National</option>
                                <option value="organization">
                                    Organization
                                </option>
                                <option value="status">Status</option>
                                <option value="comments">Comments</option>
                                <option value="old_ID">Old ID (legacy)</option>
                                <option value="joined">Joined (legacy)</option>
                            </select>
                            <label>
                                <span>
                                    Exclude Records With Empty Sort Field
                                </span>
                                <input
                                    className="mx-2"
                                    type="checkbox"
                                    onChange={changeExclusion}
                                />
                            </label>

                            <Button
                                className="my-4"
                                aria-label="Ascending/Descending"
                                onClick={changeSortOrder}
                            >
                                {sortDescending ? "Descending" : "Ascending"}
                            </Button>

                            {bayAreaOnly ? (
                                <h4>
                                    Showing {filteredPeople.length} Members
                                    Residing In Bay Area Zipcodes:
                                </h4>
                            ) : (
                                <h4>
                                    Showing {filteredPeople.length} Members
                                    Residing Anywhere:
                                </h4>
                            )}

                            <h5>Sorted By: {sortBy}</h5>
                        </Col>
                    </Row>
                    {/* </div> */}
                </Col>

                <Col>
                    <div>
                        <ul className={styles.people}>
                            {filteredPeople &&
                                sorted(filteredPeople).map(person => (
                                    <li
                                        key={person.id}
                                        onClick={e => {
                                            // console.log('you just clicked this element: ', e.target)
                                            changeActivePerson(person.id)
                                        }}
                                    >
                                        <div className={styles.name}>
                                            <Person
                                                resetActivePerson={
                                                    resetActivePerson
                                                }
                                                activePerson={activePerson}
                                                id={person.id}
                                                old_ID={person.old_ID}
                                                firstName={person.firstName}
                                                lastName={person.lastName}
                                                address={person.address}
                                                apt={person.apt}
                                                city={person.city}
                                                state={person.state}
                                                zip={person.zip}
                                                phone={person.phone}
                                                email={person.email}
                                                joined={person.joined}
                                                national={person.national}
                                                organization={
                                                    person.organization
                                                }
                                                status={person.status}
                                                comments={person.comments}
                                                dueDate={person.dueDate}
                                                createdAt={person.createdAt}
                                                duesPaid={person.duesPaid}
                                            />
                                        </div>
                                        <div className={styles.buttons}></div>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </Col>
            </Row>
        </>
    )
}
