import { useState } from "react"
//styles
import styles from "./People.module.css"

import { formatAddress } from "localized-address-format"

export default function PersonDisplay({
    id,
    old_ID,
    firstName,
    lastName,
    address,
    apt,
    city,
    state,
    zip,
    email,
    phone,
    joined,
    national,
    organization,
    status,
    comments,
    dueDate,
    createdAt,
    duesPaid
}) {
    //this function will format any name with spaces or hyphens!
    const formatName = thisName => {
        // what if there are hyphens? We need capitalization without spacing after each hyphen
        //let's define an inner function to do this:
        const capitalizeAfterHyphen = name => {
            //split the name into an array at each "dash"
            const arr = name.split("-")
            arr.forEach((word, index, array) => {
                //we couldve just used map here...

                //for any word other than the first one...
                array[index] =
                    word.substring(0, 1).toUpperCase() +
                    word.substring(1).toLowerCase()
                //capitalize the first letter, lowercase the rest of the word
            })

            return arr.join("-") //return the array, joined into a string with hyphens between each element!
        }

        //now that we have our hyphen capitalization, we need space-between words capitalzation!
        //we've already lowercased the endings of words, so all we have to do is capitalize the beginning of words

        const capitalizeAfterSpaces = name => {
            const arr = name.split(" ").map(word => {
                return word.substring(0, 1).toUpperCase() + word.substring(1)
            })
            return arr.join(" ")
        }

        return capitalizeAfterSpaces(capitalizeAfterHyphen(thisName))
    }

    const formattedAddress = formatAddress({
        postalCountry: "US",
        administrativeArea: state,
        locality: city,
        //dependentLocality: '',
        postalCode: zip,
        //sortingCode: '',
        // organization: 'Example Org.',
        name: `${
            firstName ? formatName(firstName) : organization ? organization : ""
        } ${lastName ? formatName(lastName) : ""}`,
        //if there's a firstname/lastname, capitalize the first letter and lowercase the rest!
        //if there's no first name, then let's put the organization name on the address line

        addressLines: apt
            ? [`${address}`, `Unit ${apt}`]
            : [`${address ? address : "<no address>"}`], //if there's no apartment number, don't say "Unit," and if there's no address? say "<no address>" instead of <undefined>
    })

    // const getCreatedDate = timestamp => {
    //     const date = new Date(timestamp)
    //     return date.toString()
    // }

    // console.log('heres a formatted address:', '\n', formattedAddress.join('\n'))
    return (
        <>
            <div className="mt-3">
                <div className={styles["person-name"]}>
                    {lastName ? (
                        <h6 className={styles.people}>
                            {" "}
                            {firstName ? firstName : ""} {lastName}
                        </h6>
                    ) : (
                        <h6 className={styles.people}>
                            {organization ? organization : ""}{" "}
                        </h6>
                    )}

                    <p>id: {id}</p>
                </div>
            </div>

            <section>
                {createdAt && (
                    <p>created: {createdAt.toDate().toDateString()}</p>
                )}
                <div className="color-secondary p-5">
                    {/* <p>FORMATTED ADDRESS:</p> */}
                    {formattedAddress.map((line, index) => (
                        <p key={index}>{line}</p>
                    ))}
                    {/* {address &&   <p>address: {address}</p>}
                   {apt && <p>apartment: {apt}</p>}
                  {city &&  <p>city: {city}</p>}
                   {state && <p>state: {state}</p>}
                   {zip && <p>zip: {zip}</p>} */}
                </div>
                <div className="color-primary pt-3 pb-1 px-2">
                    {email && <p>email: {email}</p>}
                    {phone && <p>phone: {phone}</p>}

                    {national && <p>national: {national}</p>}
                    {organization && <p>organization: {organization}</p>}
                    {status && <p>status: {status}</p>}
                    {comments && <p>comments: {comments}</p>}
                    {old_ID && <p>old id: {old_ID}</p>}
                </div>
                <div className="my-3 mx-2">
                    {!createdAt && (
                        <p>
                            joined date:{" "}
                            {
                                joined ? (
                                    //is there dueDate data here?
                                    typeof joined === "string" ? (
                                        dueDate //if dueDate is a string, print it
                                    ) : (
                                        joined.toDate().toDateString()
                                    ) //otherwise it's a timestamp, stringify it
                                ) : (
                                    <em>NO DATA!</em>
                                ) //if due date doesn't exist, display this!
                            }
                        </p>
                    )}

                    {/* if we have data in the createdAt timestamp field, then we also have a dues due date that's based on this data, and this will also be a timestamp! UNLESS OF COURSE... if dueDate is a stRING! */}

                    <p>
                        dues due date:{" "}
                        {
                            dueDate ? (
                                //is there dueDate data here?
                                typeof dueDate === "string" ? (
                                    dueDate //if dueDate is a string, print it
                                ) : (
                                    dueDate.toDate().toDateString()
                                ) //otherwise it's a timestamp, stringify it
                            ) : (
                                <em>NO DATA!</em>
                            ) //if due date doesn't exist, display this!
                        }
                    </p>
                    {/* {duesPaid && <p>dues last paid: {duesPaid}</p>} */}
                  <p>dues last paid:{" "}
                  {duesPaid ? (
                    duesPaid
                  )
                :
                <em>NO DATA!</em>}



                  </p>
                </div>
            </section>
        </>
    )
}
