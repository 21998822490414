import { useReducer, useEffect, useState } from 'react'
import { projectFirestore, timestamp } from '../firebase/config'

let initialState = {
    document: null,
    isPending: false,
    error: null,
    success: null
    //success will be null to begin with, but will eventually be either true or false
}

const firestoreReducer = (state, action) => {
    switch (action.type) {
        case 'IS_PENDING':
            return {
                isPending: true,
                document: null,
                success: false,
                error: null
            }
        //we're getting rid of the {...state} part of this object, where we output our previous state, because we're manually changing every property of our state! neat!
        case 'ADDED_DOCUMENT':
            return {
                isPending: false,
                document: action.payload,
                success: true,
                error: null
            }

        case 'UPDATED_DOCUMENT':
            return {
                isPending: false,
                document: action.payload,
                success: true,
                error: null
            }
        //ADDED THIS ONE MYSELF

        case 'DELETED_DOCUMENT':
            return {
                isPending: false,
                document: null, //there's no document reference when we've deleted a document
                success: true,
                error: null
            }

        case 'ERROR':
            return {
                isPending: false,
                document: null,
                success: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const useFirestore = collection => {
    //if the component that uses this hook ever unmounts, then the useEffect cleanup function at the bottom will fire!

    const [response, dispatch] = useReducer(firestoreReducer, initialState)
    //dispatch is ALWAYS the second element when we use useReducer. yay!
    //and remember: inside the useReducer hook we have two arguments: the reducer function, and then any initial state
    //response is our own custom state object that "represents" the response we get back from firestore
    const [isCanceled, setIsCanceled] = useState(false)
    //remember: we're only going to update state if isCanceled is false, which will prevent us from trying to update state on an unmounted component and trigger an error

    //collection ref:
    const ref = projectFirestore.collection(collection)
    //in our case... "collection" will be our people collection

    // only dispatch if not canceled:
    const dispatchIfNotCanceled = action => {
        //this is a nice way of abstracting the "if not canceled, do the dispatch" logic, which we're gonna use a bunch
        if (!isCanceled) {
            dispatch(action)
        }
    }

    //add a document:
    const addDocument = async doc => {
        dispatch({ type: 'IS_PENDING' }) //we don't need a payload when we dispatch an IS_PENDING action, because all we're doing is setting the isPending part of our state to be true. look at the 'IS_PENDING' case in the switch statement above: it doesn't reference payload at all

        try {
            const createdAt = timestamp.fromDate(new Date())
            //this takes the current date and time at the time you tried to add this document and passes it into the fromDate method on the timestamp object, which creates a new firebase timestamp, which we're storing in the variable createdAt
            const addedDocument = await ref.add({
                ...doc,
                // joined: createdAt.toDate().toDateString()
                createdAt
            })
            //we're NOT gonna put this createdAt value into the "joined" value, which is something that already exists in our person record

            dispatchIfNotCanceled({
                type: 'ADDED_DOCUMENT',
                payload: addedDocument
            })
            //remember: addedDocument is the document reference that we get back from the ref.add(doc) function above
        } catch (err) {
            dispatchIfNotCanceled({ type: 'ERROR', payload: err.message })
        }
    }

    //UPDATE A DOCUMENT!
    const updateDocument = async (id, updates) => {
        dispatch({ type: 'IS_PENDING' })
        // console.log('fired updateDocument...')

        try {
            const updatedDocument = await ref.doc(id).update(updates) //we'll pass in our updates object when we call this function
            //once we have our reference, then we dispatch if isCanceled is false, aka this component is mounted
            dispatchIfNotCanceled({
                type: 'UPDATED_DOCUMENT',
                payload: updatedDocument
            })
            return updatedDocument
        } catch (err) {
            dispatchIfNotCanceled({ type: 'ERROR', payload: err.message })
            return null //these returns are optional
        }
    }

    //delete a document:
    const deleteDocument = async id => {
        dispatch({ type: 'IS_PENDING' })

        try {
            await ref.doc(id).delete()
            //we don't need a payload, or to store the return from this function as a constant, because when we delete a document it just returns an object with a value of undefined
            dispatchIfNotCanceled({ type: 'DELETED_DOCUMENT' })
        } catch (err) {
            dispatchIfNotCanceled({
                type: 'ERROR',
                payload:
                    'could not delete your doc, you useFirestore-using piece of butt'
            })
        }
    }

    //cleanup function:
    useEffect(() => {
        //if the component that uses this hook ever unmounts, then this function will fire! neat!
        return () => setIsCanceled(true)
    }, [])

    return { addDocument, deleteDocument, updateDocument, response}
}
