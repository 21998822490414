import "./App.css"
import "bootswatch/dist/lux/bootstrap.min.css"
//LUX THEME: https://bootswatch.com/

//react stuff
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { useAuthContext } from "./hooks/useAuthContext"

//pages
import HomePage from "./pages/HomePage.js"
import Join from "./pages/Join.js"
import AboutUs from "./pages/AboutUs.js"
import Activism from "./pages/Activism.js"
import Events from "./pages/Events.js"
import Resources from "./pages/Resources.js"
import ChildPage from "./pages/ChildPage.js"
import Login from "./pages/Login"
import Members from "./pages/Members"
import Contact from "./pages/Contact"
import ThankYou from "./pages/ThankYou"

//components
import ActionAlerts from "./pages/ActionAlerts"
import SubPage from "./pages/SubPage"

function App() {
    const { authIsReady, user } = useAuthContext()
    //authIsReady is initially false, but then becomes true as soon as we find out the initial value for the user
    //we also need to grab "user", so we can make sure you can only access certain pages if your login status is appropriate

    return (
        <div className="App">
            {authIsReady && (
                // all of this is to get around the brief flicker of showing the wrong menus, before react figures out you're logged in

                <BrowserRouter>
                    <div>
                        <Routes>
                            {/* NOTE: i wasnt able to get these page previews to work consistently, so this next section with "preview" urls isn't really doing anything right now. It's easy to do in NextJS, all you'd need to do is rewrite the entire app to use! Hooray.  */}

                            {/* in each case, the component will check to see if the url begins with /preview, and if it does, it'll make a sanity call with the preview client! */}

                            {/* HOME */}
                            <Route element={<HomePage />} path="/" exact />
                            <Route
                                element={<HomePage />}
                                path="/preview-home"
                            />

                            {/* ABOUT US */}
                            <Route element={<AboutUs />} path="/about" />
                            <Route
                                element={<AboutUs />}
                                path="/preview-about"
                            />

                            {/* ACTIVISM navbar link... duplicate of CardSubPageSection for now */}
                            <Route element={<Activism />} path="/activism" />

                            {/* SFGP EVENTS */}
                            <Route element={<Events />} path="/events" />
                            <Route
                                element={<Events />}
                                path="/preview-events"
                            />

                            {/* SUBPAGES aka ACTIVISM PAGES */}

                            <Route
                                element={<SubPage />}
                                path="/activism/:slug"
                            />

                            <Route
                                element={<SubPage />}
                                path="/preview-activism/:slug"
                            />

                            {/* ACTION ALERTS/ PROWL n GROWL (pulls from Google Doc, NOT EDITABLE USING SANITY), no preview */}
                            <Route element={<ActionAlerts />} path="/action" />

                            {/* RESOURCES/GET HELP */}
                            <Route element={<Resources />} path="/resources" />
                            <Route
                                element={<Resources />}
                                path="/preview-resources"
                            />

                            {/* CHILD PAGES */}
                            <Route
                                element={<ChildPage />}
                                path="/child/:slug"
                            />

                            <Route
                                element={<ChildPage />}
                                path="/preview-child/:slug"
                            />

                            <Route element={<Join />} path="/join" />
                            <Route element={<Join />} path="/renew/:id" />
                            {/* https://reactrouter.com/docs/en/v6/getting-started/overview */}

                            <Route
                                path="/members"
                                element={user ? <Members /> : <Login />}
                            />

                            <Route element={<Contact />} path="/contact" />
                            <Route element={<ThankYou />} path="/thankyou" />
                        </Routes>
                    </div>
                </BrowserRouter>
            )}
        </div>
    )
}

export default App
