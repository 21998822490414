import { createContext, useReducer, useEffect } from "react"
import { projectAuth } from "../firebase/config"

export const AuthContext = createContext()

export const authReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return { ...state, user: action.payload }

        case "LOGOUT":
            return { ...state, user: null }

        case "AUTH_IS_READY":
            return { ...state, user: action.payload, authIsReady: true }

        default:
            return state
        //if the action type we pass in doesn't match any of our cases, we'll just return the current state aka do nothing!
    }
}

export const AuthContextProvider = ({ children }) => {
    // remember: the children represent whatever this component is going to wrap in the future. spoiler alert: it's gonna be App!

    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        authIsReady: false,
        //we're gonna use the authIsReady property in our App.js, so that we won't show ANY of that component tree until authIsReady is "true"
    })
    //remember: first argument is some kind of reducer function, second is the intial state! awesome!

    useEffect(() => {
        //HEY FIREBASE! FIND OUT IF SOMEONE'S LOGGED IN, AS SOON AS WE LOAD THE PAGE! if there's a user, send us that user back, otherwise, send us null
        const unsub = projectAuth.onAuthStateChanged(user => {
            // whenever there's some kind of change in authentication status, fire this function!
            // this function, since it's in a useEffect, will fire initially when we first load the page

            dispatch({ type: "AUTH_IS_READY", payload: user })
        })

        return () => unsub() //this ensures that the above check only happens ONE TIME
    }, [])

    //note: each time "state" changes, this function will re-render, and the below console log will happen again!
    //so "state" begins as {user: null}, but when we click the signup button, it fires the signup() function from useSignup, which fires off a call to firebase to sign a user up with email and pass and gets back a res object... and if this is successful then it fires off the dispatch function with an action type of LOGIN and a payload of res.user... since the action type is 'LOGIN'... the things that happen in the switch statement for 'LOGIN' will happen: aka the "state" from authReducer will be set to whatever the current state is with the additional property of {user: 'whatever res.user is'}. Confusing as fuck? Yes. Make sense? also yes.
    // console.log("here is our state object from AuthContext: ", state)

    return (
        <AuthContext.Provider value={{ ...state, dispatch }}>
            {/* "value" here is an object. with the above line, we can use the dispatch function directly inside any new hook we create, to update our context value! cool! */}
            {children}
        </AuthContext.Provider>
    )
}
