import sanityClient from '../client'
// import { Link } from 'react-router-dom'
import MyNavbar from '../components/MyNavbar'
import Footer from '../components/Footer'
import { useEffect, useState } from 'react'

import BlockContent from '@sanity/block-content-to-react'
import { Container, Row, Col } from 'react-bootstrap'

// https://www.youtube.com/watch?v=9ipNrJlIj_I&list=PLRzQpWc3zNPmzABntnD4myIsTlkbIxfuF&index=3
const serializers = {
    marks: {
        internalLink: ({children, mark}) => <a href={mark.url}>{children}</a>,
        link: ({children, mark}) => mark.blank ? (
        <a href={mark.href} target="_blank" rel="noopener noreferrer">{children}</a> //open in a new window!
       ) : ( <a href={mark.href}>{children}</a>
       )
    }
}

export default function Resources() {
    const [pageData, setPageData] = useState(null)

    useEffect(() => {
        sanityClient
            .fetch(
                 //note: _id, not _type... because we have more than one document of the type 'resources'
                `*[_id == 'resources']{
                    body[]{
                        ...,
                        markDefs[]{
                            ...,
                            _type == "internalLink" => {
                                ...,
                                "slug": @.item->slug,
                                "url": "https://panther-gray.web.app/child/" + @.item->slug.current
                            }
                        }
                    },
            }`
            )
            .then(data => setPageData(data[0]))
            .catch(console.error)
    }, [])

    if (!pageData) {
        return <h1>Loading...</h1>
    }
    return (
        <>
            <MyNavbar />

            {/* <main className=' my-5 page-content p-5 bg-warning'> */}
            {/* YO YO YO! MAIN elements have WAY different styling thatn DIVS! */}

        <Container fluid className='bg-primary' style={{ backgroundImage: `linear-gradient(rgb(109, 149, 180, 0.01), rgb(255, 253, 218, 0.4))`,  }}>
            
                <Row className="justify-content-center">
                    <Col lg={5} className="pt-3 m-4 color-primary text-center">
                        <Row>
                            
                        <h1 className="display-3 ">Resources</h1>
                        </Row>

                <Row className='justify-content-center'>
        <Col lg={12}>

                        <h6 className='fs-5'>Need help? We can help you find it.</h6>
        </Col>
                </Row>
                        
                    </Col>
                </Row>
               

                <Row className="flex align-items-center justify-content-center pt-3 pb-4">
                    <Col lg={8} className="color-secondary  p-4 p-md-5 m-lg-2">
                        {pageData && (
                            <BlockContent
                                className="p-lg-4 m-lg-4"
                                serializers={serializers}
                                blocks={pageData.body}
                                projectId={sanityClient.clientConfig.projectId}
                                dataset={sanityClient.clientConfig.dataset}
                            />
                        )}
                    </Col>
                </Row>
            </Container>
            <Footer />

          
        </>
    )
}
