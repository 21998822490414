import { useState, useEffect } from 'react'

//styles
import styles from './People.module.css'

import PersonDisplay from './PersonDisplay'
import PersonInput from './PersonInput'
import EditPerson from './EditPerson'

import Button from 'react-bootstrap/Button'

export default function Person({
    resetActivePerson,
    activePerson,
    id,
    old_ID,
    firstName,
    lastName,
    address,
    apt,
    city,
    state,
    zip,
    phone,
    email,
    joined,
    national,
    organization,
    status,
    comments,
    dueDate,
    createdAt,
    duesPaid
}) {
    const [isActive, setIsActive] = useState(false)

    //WE NEED state that says ANYTHING isActive
    //if anything isActive, then you ARE NOT active
    //if nothing is active, then you can be active

    const resetInput = () => {
        setIsActive(false)
        console.log('youre back to normal!')
    }

    //we want a useEffect to set isActive to false whenever anythingIsActive changes!
    useEffect(() => {
        // console.log('the active person just changed!')
        if (!(activePerson === id)) {
            setIsActive(false)
        }
    }, [activePerson, id])

    // console.log('active person is... ', activePerson)

    return (
        <div
            className={styles['person-record']}
           
        >

<Button  onClick={() => {
                setIsActive(!isActive)
                // setActivePerson(id)
                //if THIS person is active, any other person is inactive
            }}>Edit This Person</Button>
            {isActive ? (
                <EditPerson
                    resetActivePerson={resetActivePerson}
                    resetInput={resetInput}
                    id={id}
                    old_ID={old_ID}
                    firstName={firstName}
                    lastName={lastName}
                    address={address}
                    apt={apt}
                    city={city}
                    state={state}
                    zip={zip}
                    email={email}
                    phone={phone}
                    joined={joined}
                    national={national}
                    organization={organization}
                    status={status}
                    comments={comments}
                    dueDate={dueDate}
                   
                />
            ) : (
                <PersonDisplay
                    id={id}
                    old_ID={old_ID}
                    firstName={firstName}
                    lastName={lastName}
                    address={address}
                    apt={apt}
                    city={city}
                    state={state}
                    zip={zip}
                    email={email}
                    phone={phone}
                    joined={joined}
                    national={national}
                    organization={organization}
                    status={status}
                    comments={comments}
                    dueDate={dueDate}
                    createdAt={createdAt}
                    duesPaid={duesPaid}
                    
                />
            )}
        </div>
    )
}
