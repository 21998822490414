//this hook is all about subscribing to realtime data from a firestore collection. neat!
import { useState, useEffect, useRef } from "react"
import { projectFirestore } from "../firebase/config"

export const useCollection = (collection, _query, _putThisShitInOrder) => {
    //again... using the generic "collection" argument here is what makes this hook reusable
    //CAREFUL: _query is an array, which is a reference type, which means that each time react runs this function, it sees _query as a new thing
    //SO: we're gonna define "query" below and wrap it in a useRef, and then use .current to get its current value. this will fix the problem. cool!

    const [documents, setDocuments] = useState(null)
    const [error, setError] = useState(null)

    const query = useRef(_query).current
    //this stores the current value of our query array in the variable "query." when you wrap a reference type inside a useRef(), React won't see that thing as different on every component reevaluation
    const putThisShitInOrder = useRef(_putThisShitInOrder).current

    useEffect(() => {
        //we want to run this code right away, as soon as the component mounts, and that's what a useEffect does!
        //since collection is in our dependency array, this function will fire every time the collection changes: so any time a document is added or removed or updated or whatever. awesome!
        //then, every time we get a snapshot back, we want to update our state! This means the data that we show in the browser will always be up to date, in sync with our firestore collection. great!

        let ref = projectFirestore.collection(collection)

        if (query) {
            ref = ref.where(...query)
            //this gives us "UID == LOGGED_IN_USER'S_UID", so we're only fetching data that belongs to the logged in user
            // the .where() method is something you can tack on to the end of the .collection() method, which is what we've done above
        }

        if (putThisShitInOrder) {
            ref = ref.orderBy(...putThisShitInOrder)
            // we're just tacking another method onto .collection(collection).where(...query).orderBy()
            // the orderBy function comes from firebase!
            //orderBy takes in two arguments (that we will pass in as an array, spread out into two separate elements, which is why we need useRef): the property you want to order by (as a string), and then ascending/descending
        }

        //"realtime listener" to this particular collection
        const unsubscribe = ref.onSnapshot(
            snapshot => {
                //note: 'The listener can be cancelled by calling the function that is returned when onSnapshot is called', which is why we're naming this function "unsubscribe"

                //"snapshot" represents the collection at the moment in time that we connected to it, and will contain all the documents that are in that collection at that time

                let results = []
                snapshot.docs.forEach(doc => {
                    results.push({ ...doc.data(), id: doc.id })
                    //don't confuse this id, which is the document id that we'll use as a key prop later, with the uid, which is the unique id of the user who created the document
                    //and remember, the .data() method is the function we use to get all the data from a document, so in our case the uid, the createdAt, name, and amount
                    //so all we're doing here is creating a new object for each document that we have, adding an "id" property to that object, and then pushing that object to our "results" array.
                })

                // update the state:
                setDocuments(results)
                setError(null)
            },
            error => {
                console.log(error)
                setError(
                    `could not fetch the data from useCollection, you awesome genius. heres the error: ${error}`
                )
                //REMEMBER: there's no catch block when you use onSnapshot(), instead there's a second argument, which is a function that will run if there's an error
            }
        )

        // cleanup function: unsubscribe on unmount
        return () => unsubscribe()
    }, [collection, query, putThisShitInOrder])
    //CAREFUL! if we don't wrap query in a useRef above, since query is an array which is a reference type, we'll create an infinite loop

    return { documents, error }
}
