import React from "react"
import ReactDOM from "react-dom"
import "./index.css"


// import 'bootstrap/dist/css/bootstrap.min.css';
//i don't think i need to import bootstrap if i'm using a cdn, which i wilL!
import App from "./App"
import { AuthContextProvider } from "./context/AuthContext"

ReactDOM.render(
    <React.StrictMode>
   
        <AuthContextProvider>
            <App />
        </AuthContextProvider>
      
    </React.StrictMode>,
    document.getElementById("root")
)
