import { useState, useEffect } from 'react'
import { projectAuth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useLogout = () => {
    const [isCanceled, setIsCanceled] = useState(false)
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const { dispatch } = useAuthContext() //this should be familiar by now, right? we're gonna need to use this dispatch function again!

    const logout = async () => {
        setError(null)
        setIsPending(true)
        //reset the error state, and make sure the pending state is true since we have a request in progress!

        //sign the user out:
        try {
            await projectAuth.signOut()
            //this is all we need to do to log a guy out! wow!

            //dispatch logout action:
            dispatch({ type: 'LOGOUT' })
            //we don't need a payload here, because we don't need to set the user to anything other than null. We'll just set user:null as part of our state in the dispatch switch statement. neat!

            //update the state:
            if (!isCanceled) {
                //isCanceled is only true if this component is unmounted, so this block ensures that we will NOT update the state on an unmounted component! cool!
                setIsPending(false)
                setError(null)
            }
        } catch (err) {
            if (!isCanceled) {
                console.log(err.message)
                setError(err.message)
                setIsPending(false)
            }
        }
    }

    //we need a cleanup function, just in case whatever component that's using this hook unmounts!
    //this useEffect will run immediately when this component is mounted, and will only run the cleanup function in the return statement if the component is unmounted
    useEffect(() => {
        return () => setIsCanceled(true)
        //we're not REALLY going to cancel anything, but we're going to make it so the state above this useEffect ONLY UPDATES IF ISCANCELED IS FALSE! clever!
    }, [])

    return { logout, error, isPending }
}
