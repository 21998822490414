import { useState, useEffect, useCallback, useRef } from "react"

import { useFirestore } from "../hooks/useFirestore"

import { useAddressFormat } from "../hooks/useAddressFormat"

// import ModalPersonInput from './ModalPersonInput';
// import ModalNewPerson from "./ModalNewPerson"

// https://www.youtube.com/watch?v=AGDaLOawJSc

//Bootstrap-react

import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

// import ReCAPTCHA  from "react-google-recaptcha"

export default function AddPersonManually() {
    const [show, setShow] = useState(false)
    // const [paymentAmount, setPaymentAmount] = useState('30')

    // const [showInput, setShowInput] = useState(false)
    //toggle whether to show the 'edit this person' input
    const [showDelete, setShowDelete] = useState(false)
    //are you SURE you wanna delete this record?!~?!
    const [showSubmit, setShowSubmit] = useState(false)
    //this state is for our modal. it controls whether you see a big "SUBMIT" button or an ARE  YOU SURE confirmation/cancel pair of buttons

    const [person, setPerson] = useState({
        firstName: "",
        lastName: "",
        address: "",
        apt: "",
        city: "",
        state: "",
        zip: "",
        email: "",
        phone: "",
        national: "",
        organization: "",
        status: "",
        comments: "",
        dueDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)), //we need a date one year in the future
        signedUpViaWebsite: false,
        //we need a new field to show the person signed themself up!
        addedManually: true,
        //
    })

    const { addDocument, response } = useFirestore("people")

    // const recaptchaRef = useRef()
    // https://github.com/dozoisch/react-google-recaptcha
    // console.log(recaptchaRef)

    const contactInfo = [
        ...useAddressFormat(person),
        person.email || "<no email>",
        person.phone || "<no phone number>",
        `organization: ${person.organization || "<not specified>"}`,
        `national: ${person.national || "<not specified>"}`,
        `status: ${person.status || "<not specified>"}`,
        `comments: ${person.comments || "<no comments>"} `,
    ]

    const handleClose = () => {
        setShow(false)
        console.log("handleClose fired! ", show)
    }
    const handleShow = () => setShow(true)

    const handleSubmit = async e => {
        e.preventDefault() //dont reload the page!

        addDocument(person)

        // const token = await recaptchaRef.current.executeAsync()
        // if (token) {
        //     console.log("token is... ", token)
        //     addDocument(person)
        // //add this person to the SFGP database!

        // handleClose()
        // }

        // if (!token) {
        //     console.log("no token")
        // }
    }

    const areYouSure = e => {
        e.preventDefault()
        setShowSubmit(true)
    }
    const notSure = e => {
        e.preventDefault()
        setShowSubmit(false)
    }

    useEffect(() => {
        //we're going to run this useEffect every time the success property on the response object changes!
        // setPerson(person)
        if (response.success) {
            alert(
                "success! you should see this change when you reload the page.",
                response
            )
            // setFirstName(personData.firstName)

            //when our success property changes, if we have a successful response aka response.success === true, then reset the name field!
            //WE DONT ACTUALLY need to reset the name field in the input, because we're just gonna go back to the Display version where the info is in <p> tagz

            //and then: make the form inactive:
            // setThisPerson({})
        }
    }, [response])

    return (
        <>
            <Button variant="primary" onClick={handleShow} className="">
                Add A New Person
            </Button>

            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {person.firstName
                            ? person.firstName
                            : "<no first name>"}{" "}
                        {person.lastName ? person.lastName : "<no last name>"}
                        <p>{person.organization}</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="color-secondary">
                    <Row>
                        <div className="">
                            <p>Add a new person's record to the database: </p>
                        </div>
                    </Row>
                    <Form
                        onSubmit={handleSubmit}
                        className="color-tertiary p-5"
                    >
                        {/* <ReCAPTCHA 
                        sitekey="6LdN3gMjAAAAABHScDVs1z6IjoyQcNlSP62JX5oQ"
                        size="invisible"
                        ref={recaptchaRef}/> */}
                        {/* ugh, so many errors, all my fault, that had to do with using the wrong capitalization of recaptcha. what an inane, stupid word, that could be spelled hundreds of different ways */}

                        <Row className="justify-content-center">
                            <Col md={6} className="">
                                <Form.Group>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.firstName}
                                        value={person.firstName || ""}
                                        onChange={e => {
                                            console.log("this person: ", person)
                                            setPerson({
                                                ...person,
                                                firstName: e.target.value,
                                            })
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.lastName}
                                        value={person.lastName || ""}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                lastName: e.target.value,
                                            })
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.email}
                                        value={person.email || ""}
                                        onChange={e => {
                                            console.log("this person: ", person)
                                            setPerson({
                                                ...person,
                                                email: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        // type="tel"
                                        // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                        placeholder={person.phone}
                                        value={person.phone || ""}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                phone: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Zip Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.zip}
                                        value={person.zip || ""}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                zip: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>

                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Street Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.address}
                                        value={person.address || ""}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                address: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Apartment</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.apt}
                                        value={person.apt || ""}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                apt: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.city}
                                        value={person.city || ""}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                city: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>State</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.state}
                                        value={person.state || ""}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                state: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>

                            <Col className="color-primary m-3 p-3">
                                <Form.Group>
                                    <Form.Label>National (Y/N)</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.national}
                                        value={person.national || ""}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                national: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Organization</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.organization}
                                        value={person.organization || ""}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                organization: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={person.status}
                                        value={person.status || ""}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                status: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Comments</Form.Label>
                                    <Form.Control
                                        // type="text"
                                        as="textarea"
                                        placeholder={person.comments}
                                        value={person.comments || ""}
                                        onChange={e => {
                                            setPerson({
                                                ...person,
                                                comments: e.target.value,
                                            })
                                        }}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>

                            {!showSubmit && (
                                <Row>
                                    <Col lg={4}>
                                        <Button
                                            className="mt-4 border border-2 border-warning"
                                            variant="primary"
                                            onClick={areYouSure}
                                        >
                                            Submit This New Record
                                        </Button>
                                    </Col>
                                </Row>
                            )}

                            {showSubmit && (
                                <section
                                    style={{
                                        backgroundColor: "var(--color-link)",
                                    }}
                                    className="p-5 mt-5"
                                >
                                    <h6 className="display-6 text-white">
                                        Are you sure you want to submit this
                                        record?
                                    </h6>
                                    <p className="text-white">
                                        This record will automatically be added
                                        to the email database at Sender.net
                                    </p>
                                    <small className="text-white">
                                        After you submit, you may want to check
                                        Sender.net to make sure this record was
                                        copied over correctly.
                                    </small>
                                    <Row className="my-5">
                                        <Col>
                                            <Row
                                                className="color-secondary p-4 "
                                                // style={{maxHeight: '12rem'}}
                                            >
                                                <h6>
                                                    RECORD YOU'RE ABOUT TO
                                                    CREATE:
                                                </h6>
                                                <hr />
                                                {contactInfo.map(line => (
                                                    <p key={line}>{line}</p>
                                                ))}
                                            </Row>
                                        </Col>
                                        <Col className="mx-3">
                                            <Button
                                                className="mx-2 my-5 text-black rounded     fs-2  "
                                                variant="warning"
                                                size="lg"
                                                type="submit"
                                                onClick={handleSubmit}
                                            >
                                                Yes, Everything Looks Good!
                                            </Button>
                                            <Button
                                                className="mx-2 rounded fs-3"
                                                variant="primary"
                                                size="lg"
                                                onClick={notSure}
                                            >
                                                No, Cancel Without Making Any
                                                Changes!
                                            </Button>
                                        </Col>
                                    </Row>
                                </section>
                            )}
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
