import { useState, useEffect } from "react"
import { useCollection } from "../hooks/useCollection"
import TableTwo from "../components/TableTwo"
import People from "../components/People"
import AddPersonManually from "../components/AddPersonManually"
import Logout from "./Logout"

//React Bootstrap components:
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

//no state here, because it's all in the peoeple/person components (other than tableview true/false state)
//WE MAKE THE CALL TO THE DATABASE IN THIS COMPONENT, and then pass it down to child components and filter that data in those ones

export default function Members() {
    const [tableView, setTableView] = useState(true)
    //if true, we'll render the react bootstrap table instead of my card view

    const changeView = () => {
        //simple toggle, true vs false
        setTableView(prevState => !prevState)
    }
    // i took this option OUT! There is only Table View, no People View.

    const { documents, error } = useCollection(
        "people"

        // null,
        // ['lastName', 'desc']

        // ["uid", "==", user.uid],
        //grab our array of documents in the 'transactions' collection, and any error if there is one
        //the second argument is an array of 3 strings, which we're going to plug into our .where() method to get a query on this collection... so that we only return the documents WHERE the user id EQUALS the current user id. so the logged-in person can only see the documents that they themself added. awesome!
        // ["createdAt", "desc"]
        //this array will be the two arguments for our orderBy() method: first the property we want to order by, and then whether we want ascending or descending. neat!
    )

    return (
        <div>
            <Container>
                <Row className="mt-2">
                <Col md={2}>
                    <Logout />
                    </Col>
                    <Col md={2}>
                        <AddPersonManually />
                    </Col>
                    <Col md={2}></Col>
                </Row>
            </Container>

            <div>
                <h1 className="text-center my-1 pb-2">
                    GRAY PANTHERS MEMBER ROSTER
                </h1>

                {error && <p>{error}</p>}

                {tableView
                    ? documents && <TableTwo people={documents} />
                    : documents && <People people={documents} />}
            </div>
        </div>
    )
}
