import { useState, useEffect, useCallback } from "react"
import { useNavigate } from "react-router"
import { useFirestore } from "../hooks/useFirestore"

// https://www.youtube.com/watch?v=AGDaLOawJSc

//Bootstrap-react

// import Button from "react-bootstrap/Button"
// import Modal from "react-bootstrap/Modal"
// import Form from "react-bootstrap/Form"
// import Row from "react-bootstrap/Row"
// import Col from "react-bootstrap/Col"
// import Container from "react-bootstrap/Container"

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"

// https://github.com/paypal/react-paypal-js
const initialOptions = {
    "client-id":
        "AekGb3_Pr65i0WbgVrV-SiPRINq3GsKAtp_kqweGYIoZT5APOenLJq9E5MyGgTkajHD9BGRLJofkcgr5",

    currency: "USD",
    intent: "capture",
}

// "data-client-token": "abc123xyz==",

// "AVaMkviPZNDLVLKqa91dWdHSzLgO_Hy6TognoqIesUmDh3KbXFnEnr4PLfLlVnyFuUW_kc9eQMuzg7M-",
//NOPE! the above AVaMk is the test client-id!

export default function PayPalModal({ personData, renewal }) {
    const [show, setShow] = useState(false)
    const [showCustomDonation, setShowCustomDonation] = useState(false)
    // show the "custom donation" input field
    const [paymentAmount, setPaymentAmount] = useState("30")
    const [showPleaseWait, setShowPleaseWait] = useState(false)

    const [success, setSuccess] = useState(false)
    // this is to try and check if response.success exists

    const { addDocument, updateDocument, response } = useFirestore("people")

    const newDueDate = new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
    )
    //we need a date one year in the future!

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const handlePaymentAmount = useCallback(
        e => setPaymentAmount(e.target.value),
        [setPaymentAmount]
    )

    const handleCustomDonation = () => {
        // console.log("fired handleCustomDonation")
        setShowCustomDonation(!showCustomDonation)
    }

    const navigate = useNavigate()

    useEffect(() => {
        //we're going to run this useEffect every time the success property on the response object changes!
        //we're trying to store this in its OWN state, to avoid getting the error the first time the page loads!

        //THE RESPONSE OBJECT IS THE STATE FROM USEREDUCER, something like:
        // {
        // document: null
        // error: null
        // isPending: true
        // success: false
        // }

        if (success) {
            // console.log("heres the successful response: ", response)
            setShowPleaseWait(true)

            setTimeout(() => {
                //CHANGED THIS! HEY HEY HEY!
                window.location.replace(
                    // console.log(
                    // "https://funraise.org/give/Gray-Panthers-of-San-Francisco/11c57618-a027-4432-b2b1-43e8b5264382"
                    "https://sfgraypanthers.org/thankyou"
                )
            }, 1000)
        } else {
            if (response.error) {
                alert(
                    "ERROR: your renewal was not processed. Please contact SFGP.",
                    response
                )
            }
        }
    }, [success, navigate, response])

    useEffect(() => {
        //when response changes, if it's successful, great! make the success state true.
        if (response.success) {
            setSuccess(true)
        }
    }, [response])

    // console.log(
    //     "this is the personData object (inside PayPalModal.js, where it gets received as a prop): ",
    //     personData
    // )

    return (
        <>
            {showPleaseWait ? (
                // if 'showPleaseWait' is true, then we're waiting for the timeout to take us to the confirmation page... so show this little message instead of the paypal buttons!
                <div className="bg-caution">
                    <p>Processing your request, please wait...</p>
                    <p>
                        When successful, you will be redirected to a
                        confirmation page.
                    </p>
                </div>
            ) : (
                <div style={{ padding: "2rem", margin: "1rem" }}>
                    <p>I choose to contribute:</p>
                    <h2> ${paymentAmount}</h2>

                    <PayPalScriptProvider
                        options={{
                            "client-id":
                                "AekGb3_Pr65i0WbgVrV-SiPRINq3GsKAtp_kqweGYIoZT5APOenLJq9E5MyGgTkajHD9BGRLJofkcgr5",
                        }}
                    >
                        {showCustomDonation ? (
                            //custom donation checkbox controls whether we display the SELECT wheel or the custom input box
                            <label htmlFor="">
                                Please enter a dollar amount:
                                <input
                                    // type="text"
                                    type="number"
                                    onChange={e =>
                                        setPaymentAmount(e.target.value)
                                    }
                                    placeholder=" 500.00"
                                />
                            </label>
                        ) : (
                            <label htmlFor="">
                                Change contribution amount:
                                <select
                                    name=""
                                    id=""
                                    value={paymentAmount}
                                    onChange={handlePaymentAmount}
                                    style={{ margin: "1rem" }}
                                >
                                    <option value="30.00">
                                        Basic Member - $30
                                    </option>
                                    <option value="50.00">
                                        Contributing Member - $50
                                    </option>
                                    <option value="75.00">
                                        Supporting Member - $75
                                    </option>
                                    <option value="100.00">
                                        Sustaining Member - $100
                                    </option>
                                </select>
                            </label>
                        )}

                        <label htmlFor="">
                            enter a different amount:
                            <input
                                type="checkbox"
                                onChange={handleCustomDonation}
                                style={{ margin: "1rem" }}
                            />
                            {/* first argument of an onChange function would be the event object... we dont want that, we want the PREVSTATE from our state-changing function */}
                        </label>

                        <PayPalButtons
                            forceReRender={[paymentAmount]} //very cool: passing a custom prop here and plugging in an object can force a rerender any time that object changes!
                            createOrder={(data, actions) => {
                                return actions.order
                                    .create({
                                        purchase_units: [
                                            {
                                                amount: {
                                                    value: `${paymentAmount}`,
                                                },
                                            },
                                        ],
                                    })
                                    .then(orderId => {
                                        // Your code here after create the order
                                        return orderId
                                    })
                            }}
                            onApprove={function (data, actions) {
                                return actions.order
                                    .capture()
                                    .then(function () {
                                        //if we passed renewal=true as a prop, then run updateDocumednt on their id, otherwise it's a new subscriber
                                        if (renewal) {
                                            // console.log("it IS a renewal!~")
                                            // syntax is updateDocument(id, dataToUpdate)

                                            //we'll record the person's renewal notes in the STATUS field of our DB records
                                            updateDocument(personData.id, {
                                                duesPaid: paymentAmount,
                                                dueDate: newDueDate,
                                                status: personData.memberNotes,
                                                signedUpViaWebsite: true
                                            })
                                        } else {
                                            // console.log("it is NOT a renewal!")

                                            addDocument({
                                                ...personData,
                                                duesPaid: paymentAmount,
                                                dueDate: newDueDate,
                                                signedUpViaWebsite: true
                                            })
                                        }
                                    })
                            }}
                        />
                    </PayPalScriptProvider>
                </div>
            )}
        </>
    )
}
